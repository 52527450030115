/* This is a css separated as Facelift is Done on Corporate from Grethels Design */

.our-mission-icon {
    height: 70px;
    width: 70px;
}

.our-mission-icon img {
    height: 100%;
    width: 100%;
}

@media (max-width: 1270px) {
    .mission-img-container {
        display: none !important;
    }
}

.traps-subtitle {
    font-size: 1.6rem;
    font-weight: 500;
}

.our-service-card-title-container {
    height: 10%;
}

.our-service-card-text-container {
    height: 55%;
}

.our-service-card-btn-container {
    height: 10%;
}

.traps-btn {
    background-color: #652CB3;
    border-radius: 100px;
    padding: 0.8rem;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 100%;
    text-align: center;
    text-transform: none;
    color: #d7d7d8;
    align-items: flex-start;
    border: 1px solid white !important;
}

.traps-btn:hover {
    color: #ffffff;
    background-color: #8e57da;
}


@media (max-width: 920px) {
    .traps-btn {
        font-weight: 700;
        font-size: 14px;
    }
}

.built-environment-engine-section {
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    /* Ensures the background image covers the entire section */
    background-position: center;
    /* Centers the background image */
    object-fit: cover;
}

@media (max-width: 720px) {
    .built-environment-engine-section {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}

.built-environment-engine-title {
    font-family: "Rajdhani";
    font-weight: 700;
    font-size: 4rem;
    line-height: 130%;
    text-align: left;
    letter-spacing: 2px;
    color: #ffffff;
    text-transform: uppercase;
    padding-left: 5rem;
}

@media (max-width: 1450px) {
    .built-environment-engine-title {
        font-size: 3.4rem;
    }
}

@media (max-width: 1180px) {
    .built-environment-engine-title {
        font-size: 3rem;
    }
}

.built-environment-engine-subtitle {
    font-family: "Rajdhani";
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 130%;
    text-align: left;
    color: #ffffff;
    padding-left: 5rem;
}

@media (max-width: 1450px) {
    .built-environment-engine-subtitle {
        font-size: 1.3rem;
    }
}

@media (max-width: 1280px) {
    .built-environment-engine-subtitle {
        font-size: 1.2rem;
    }
}

.built-environment-engine-container {
    background-color: #28282d;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-block: 2rem;
    width: 80%;
}

@media (max-width: 1450px) {
    .built-environment-engine-container {
        padding-right: 3rem;
        width: 85%;
    }
}

@media (max-width: 1280px) {
    .built-environment-engine-container {
        padding-right: 2rem;
        width: 90%;
    }
}

.built-environment-engine-p {
    font-family: "Rajdhani";
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 150%;
    text-align: left;
    color: #ffffff;
}

@media (max-width: 1450px) {
    .built-environment-engine-p {
        font-weight: 500;
        font-size: 1.1rem;
    }
}

.built-environment-engine-p-line {
    border-bottom: 3px solid white;
    width: 50%;
}

.built-environment-engine-card {
    background-color: transparent;
}

.built-environment-engine-grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-right: 5rem;
}

.built-environment-engine-card-title {
    font-family: "Rajdhani";
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    color: #ffffff;
}

.bee-flex {
    display: flex;
    flex-direction: row;
}

.bee-container {
    height: 100%;
    width: 50%;
}

@media (max-width: 1200px) {
    .bee-flex {
        display: flex;
        flex-direction: column;
    }

    .bee-container {
        height: 100%;
        width: 100%;
    }

    .built-environment-engine-grid {
        padding-right: 3rem;
        padding-left: 3rem;
        gap: 0.5rem;
    }

    .overlay-text {
        font-size: 16px !important;
    }
}


@media (max-width: 820px) {
    .built-environment-engine-grid {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .overlay-text {
        font-size: 14px !important;
    }
}

@media (max-width: 780px) {
    .built-environment-engine-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .overlay-text {
        font-size: 22px !important;
    }

    .built-environment-engine-title {
        padding-left: 2.5rem !important;
    }

    .built-environment-engine-subtitle {
        padding-left: 2.5rem;
    }

    .built-environment-engine-container {
        padding-left: 2.5rem;
    }
}

@media (max-width: 630px) {
    .overlay-text {
        font-size: 18px !important;
    }
}

@media (max-width: 480px) {
    .overlay-text {
        font-size: 14px !important;
    }
}

.container-qubeToken-Section {
    /* background-image: url("/img/BG\ 1.svg"); */
    background-repeat: no-repeat;
    padding-left: 1em;
    padding-right: 1em;

}

@media (max-width: 720px) {
    .container-qubeToken-Section {
        padding-left: 0.4em;
        padding-right: 0.4em;
    }
}

.qubeToken-Section {
    background-color: #000;
    background-image: url('../src/images/CorporateFacelift/img/qubetoken-bg-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* Ensures the background image covers the entire section */
    background-position: center;
    /* Centers the background image */
    object-fit: cover;
}

@media (min-width: 1280px) {
    .qubeToken-Section {
        min-height: calc(100vh - 5.1rem);
    }
}

@media (max-width: 720px) {
    .qubeToken-Section {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}

.qubeToken-title {
    font-family: "Rajdhani";
    font-weight: 700;
    font-size: 5rem;
    line-height: 125%;
    /* identical to box height, or 70px */
    text-align: center;
    letter-spacing: 2px;
    color: #ffffff;
}

@media (max-width: 1280px) {
    .qubeToken-title {
        font-size: 4rem;
    }
}

@media (max-width: 720px) {
    .qubeToken-title {
        font-size: 3.3rem;
    }
}

.qubeToken-subtitle {
    font-family: "Rajdhani";
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 125%;
    /* identical to box height, or 70px */
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
}


@media (max-width: 920px) {
    .qubeToken-subtitle {
        font-size: 2rem;
    }
}

@media (max-width: 720px) {
    .qubeToken-subtitle {
        font-size: 1.5rem;
    }
}


.qubeToken-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.qubeToken-mid {
    display: flex;
    flex-direction: column;
}



.qubeToken-mid-small {
    display: none;
    flex-direction: column;
}

@media (max-width: 1280px) {
    .qubeToken-mid {
        display: none;
    }

    .qubeToken-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .qubeToken-mid-small {
        display: flex;
    }
}

.qubeToken-side {
    padding-left: 3rem;
    padding-right: 3rem;
}

@media (max-width: 1520px) {
    .qubeToken-side {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width: 1480px) {
    .qubeToken-side {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }

    .qubeToken-title-container {
        margin-bottom: 0.6rem;
    }
}

.qubeSection-btn {
    background-color: #652CB3;
    border-radius: 100px;
    padding: 0.8rem;
    width: 250px;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 100%;
    text-align: center;
    text-transform: none;
    color: #d7d7d8;
    align-items: flex-start;
    border: 1px solid white !important;
}

.qubeSection-btn:hover {
    color: #ffffff;
    background-color: #8e57da;
}


@media (max-width: 920px) {
    .qubeSection-btn {
        font-weight: 700;
        font-size: 18px;
    }
}

.shop-Section {
    background-image: url('../src/images/CorporateFacelift/img/shop-bg-image.png');
    background-color: rgba(0, 0, 0, 0.6);
    /* Semi-transparent black layer */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    /* Blends the background color with the image */
    object-fit: cover;
    padding-bottom: 5rem !important;
}

@media (min-width: 1280px) {
    .shop-Section {
        min-height: calc(100vh - 5.1rem);
    }
}

@media (max-width: 720px) {
    .shop-Section {
        padding-top: 5rem !important;
    }
}

.container-shop-Section {
    /* background-image: url("/img/BG\ 1.svg"); */
    background-repeat: no-repeat;
    padding-left: 1em;
    padding-right: 1em;

}

@media (max-width: 720px) {
    .container-shop-Section {
        padding-left: 0.4em;
        padding-right: 0.4em;
    }
}

.shop-btn {
    background-color: #652CB3;
    border-radius: 100px;
    padding: 0.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 100%;
    text-align: center;
    text-transform: none;
    color: #d7d7d8;
    align-items: flex-start;
    border: 1px solid white !important;
}

.shop-btn:hover {
    color: #ffffff;
    background-color: #8e57da;
}


@media (max-width: 920px) {
    .shop-btn {
        font-weight: 700;
        font-size: 14px;
    }
}

.peb-Section {
    background-image: url('../src/images/CorporateFacelift/img/peb-bg-image.png');
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black layer */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    /* Blends the background color with the image */
    object-fit: cover;
    padding-bottom: 3rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 3rem !important;
    
}

@media (min-width: 1280px) {
    .peb-Section {
        min-height: calc(100vh - 5.1rem);
    }
}

.container-peb-Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;

}

@media (max-width: 720px) {
    .container-peb-Section {
        padding-left: 0.4em;
        padding-right: 0.4em;
    }
}

.dao-Section {
    position: relative;
    overflow: hidden;
    padding-bottom: 5rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    padding-top: 3rem !important;
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }
  
  .container-dao-Section {
    position: relative;
    z-index: 1; /* Ensure content is above the video */
  }
  
  @media (min-width: 1280px) {
    .dao-Section {
      min-height: calc(100vh - 5.1rem);
    }
  }
  
  .community-Section {
    background-image: url('../src/images/CorporateFacelift/img/community-bg-image.jpeg');
    background-color: rgba(0, 0, 0, 0.6);
    /* Semi-transparent black layer */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    /* Blends the background color with the image */
    object-fit: cover;
    padding-bottom: 3rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 3rem !important;
}

@media (min-width: 1280px) {
    .community-Section {
        min-height: calc(100vh - 5.1rem);
    }
}

.container-community-Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;

}

@media (max-width: 720px) {
    .container-community-Section {
        padding-left: 0.4em;
        padding-right: 0.4em;
    }
}

.dapp-Section {
    background-color: #000;
    /* Semi-transparent black layer */
    /* Blends the background color with the image */
    min-height: calc(100vh - 5.1rem) !important;
    padding: 0;
}


.container-dapp-Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100% !important;
    width: 100% !important;
}

.scrollText {
    display: block;
}

@media (max-width: 1280px) {
    .scrollText {
        display: none;
    }
}

/* AudioToggleFooter.css */

/* Footer container styles */
.audio-footer {
    position: fixed;
    bottom: 2rem;
    left: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.5rem 1.5rem 2rem; /* p-6 py-6 md:py-8 */
    z-index: 10;
    pointer-events: none;
  }
  
  /* Audio toggle button styles (div element) */
  .audio-toggle-button {
    background-color: rgba(255, 255, 255, 0.2); /* Navy blue with opacity */
    border-radius: 1.25rem; /* rounded-2xl */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0.75rem 1rem; /* py-3 px-4 for small screens, md:py-4 md:px-6 */
    cursor: pointer;
    pointer-events: auto; /* Make div clickable */
    transition: background-color 0.3s ease;
    border: none;
  }
  
  /* Icon size */
  .audio-toggle-button .icon {
    height: 2.3rem;
    width: 2.3rem;
    color: white;
  }
  
  /* Hover effect for button */
  .audio-toggle-button:hover {
    background-color: rgba(250, 250, 250, 0.3); /* Slightly darker on hover */
  }
  

  /* Add this CSS to your styles */
.icon {
    transition: transform 0.2s ease;
}

.icon.jiggle {
    animation: jiggle 0.6s ease-in-out infinite; /* Use 'infinite' to keep it running */
}

@keyframes jiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(-10deg); }
    75% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
}

  