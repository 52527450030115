/* fix design  */
* {
  font-family: "Rajdhani", sans-serif;
}

main {
  min-height: calc(100.5vh - 380px);
}

.a-pipe {
  color: #000;
  text-decoration: underline;
  font-weight: bold;
}
.a-pipe:hover {
  color: #000;
}
.a-link {
  color: rgb(215, 215, 216) !important;
  text-decoration: none;
}
.a-link:hover {
  color: rgb(215, 215, 216);
}
.active {
  font-weight: bold !important;
  color: #ffffff !important;
  text-decoration: underline !important;
}

.nav {
  flex-wrap: nowrap !important;
}
.navbar-expand-lg {
  background-color: #000;
  left: 0px;
  top: 0px;
}
.nav-item-pipehome {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 135%;
  letter-spacing: 1px;

  /* or 24px */

  /* text-transform: uppercase; */

  /* Primary/5 */

  color: #ffffff !important;
  text-align: center;
  /* color: #d7d7d8; */
}
.nav-item-QTrans {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.nav-item-QToken {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.nav-item-CarbonCred {
  left: 50.52%;
  right: 40%;
  top: 32%;
  bottom: 32%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.nav-item-People {
  left: 62.84%;
  right: 27.68%;
  top: 32%;
  bottom: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.nav-item-Resource {
  left: 62.84%;
  right: 27.68%;
  top: 32%;
  bottom: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
  color: #d7d7d8;
}
.nav-item-FAQ {
  left: 75.17%;
  right: 15.36%;
  top: 32%;
  bottom: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.nav-item-Contacts {
  left: 75.17%;
  right: 15.36%;
  top: 32%;
  bottom: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.nav-item-ipAssets {
  left: 75.17%;
  right: 15.36%;
  top: 32%;
  bottom: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.nav-item-BuyQube {
  left: 75.17%;
  right: 15.36%;
  top: 32%;
  bottom: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.navbar-toggler {
  background-color: #d7d7d8;
}
/* .logo {
  width: 184.02px;
  height: 41.43px;
} */

.aboutlink {
  background-color: #2f2f2f;
  color: #d7d7d8;
  border-style: none;
  text-transform: uppercase;
  font-size: 15px;
  padding: 8px;
}

.reslink {
  padding: 8px;
  color: white !important;
  display: flex;
  justify-content: center;
}

.navbar-light .navbar-nav .nav-link {
  color: #d7d7d8;
}
.button1 {
  background: linear-gradient(44.73deg, #7c7c7c 11.32%, #4e4e4e 92.96%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 165px;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.button1:hover {
  color: #ffffff;
}
.container-content {
  display: flex;
}
.contaner-contact {
  width: 100%;
}
.yt-vid {
  width: 560px;
  height: 315px;
  background-color: #000000;
}

.showcase1 table {
  overflow: hidden;
  box-shadow: 0px 0px 10px 0.5px #d9d9d9;
}

@media (min-width: 310px) {
  .yt-vid {
    width: 100%;
    height: auto;
    margin-top: 1em;
  }
  .container-content {
    flex-direction: column;
  }
}
@media (min-width: 390px) {
  .yt-vid {
    width: 100%;
    height: auto;
    margin-top: 1em;
  }
  .container-content {
    flex-direction: column;
  }
}
@media (min-width: 471px) {
  .yt-vid {
    width: 100%;
    height: auto;
    margin-top: 1em;
  }
  .container-content {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .yt-vid {
    width: 500px;
    height: 300px;
  }
  .container-content {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .yt-vid {
    width: 560px;
    height: 315px;
    margin-top: 5em;
  }
  .container-content {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .yt-vid {
    width: 560px;
    height: 315px;
    margin-top: 0;
  }
  .container-content {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .yt-vid {
    width: 560px;
    height: 315px;
    margin-top: 0;
  }
  .container-content {
    flex-direction: row;
  }
}
.intro {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 4.6rem;
  line-height: 120%;
  /* or 67px */

  text-transform: none;
  text-align: left !important;

  color: #ffffff;
  margin-bottom: 1rem;
}

@media (max-width: 1280px) {
  .intro {
    font-size: 4.2rem;
  }
}

@media (max-width: 980px) {
  .intro {
    font-size: 3.8rem;
  }
}

@media (max-width: 800px) {
  .intro {
    font-size: 3.3rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 700px) {
  .intro {
    font-size: 3.1rem;
  }
}
  
@media (max-width: 500px) {
  .intro-btn2 a {
    width: 90% !important;
  }
}

@media (max-width: 450px) {
  .intro {
    margin-bottom: 3rem;
  }
}
@media (max-width: 570px) {
  .new-hero-tag  {
    font-size: 26px !important;
  }
}
@media (max-width: 450px) {
  .new-hero-tag  {
    font-size: 22px !important;
  }
}

.pre-intro {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 120%;
  /* or 67px */
  text-align: start !important;
  text-transform: none;

  color: #652CB3;
}
.intro-sentence {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: left;
}
.intro-text-2 {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  justify-content: center;
  color: #5a5a5a;
}
.intro-text-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #5a5a5a;
}
.intro-text-3 a {
  color: #5a5a5a;
}
.bg-icon {
  position: relative;
  top: 35px;
  left: 95px;
  width: 600px;
}
.showcase-intro {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  color: #000000;
  padding-top: 150px;
}
.showcase-intro-qb {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  color: #000000;
}
.showcase-intro-contact {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  color: #000000;
}
.showcase-intro-lead {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #5a5a5a;
}
.showcase-intro-lead-carbon {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #5a5a5a;
  text-align: left;
}
.showcase-intro-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #5a5a5a;
  padding-left: 140px;
  padding-top: 200px;
  padding-bottom: 100px;
}
.footbar {
  background-color: #c4c4c4;
}
.footbar-text {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  color: #000000;
  position: relative;
  top: 10px;
}
.footbar-text-lead {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  text-transform: none;
  color: #5a5a5a;
}
.footbar-button {
  background: linear-gradient(44.73deg, #2a2929 11.32%, #2b2b2b 92.96%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 200px;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #d7d7d8;
}
/* media for footbar */
@media (min-width: 310px) {
  .footbar-text {
    font-size: 1em;
    line-height: 1em;
    text-align: center;
  }
  .footbar-text-lead {
    font-size: 1em;
    line-height: 1em;
  }
}
@media (min-width: 576px) {
  .footbar-text {
    font-size: 1.5em;
    line-height: 1em;
    text-align: center;
  }
  .footbar-text-lead {
    font-size: 1em;
    line-height: 1em;
  }
}
@media (min-width: 768px) {
  .footbar-text {
    font-size: 1.8em;
    line-height: 41px;
    text-align: left;
  }
  .footbar-text-lead {
    font-size: 25px;
    line-height: 36px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .footbar-text {
    font-size: 2em;
    line-height: 41px;
    text-align: none;
  }
  .footbar-text-lead {
    font-size: 25px;
    line-height: 36px;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .footbar-text {
    font-size: 32px;
    line-height: 41px;
    text-align: none;
  }
  .footbar-text-lead {
    font-size: 28px;
    line-height: 36px;
    text-align: left;
  }
}
/* ----  */
.footbar-button:hover {
  color: #ffffff;
}
.footbar-btn {
  text-align: center;
}
/* Jade's Codes  */
footer {
  margin-top: auto;
}
.footer-icon {
  width: 1rem;
  height: 1rem;
}
.flink-icon {
  color: dark;
  text-decoration: none;
}
@media (min-width: 768px) {
  .footer-icon {
    margin-top: 1rem;
  }
}
@media (min-width: 992px) {
  .footer-icon {
    margin-top: 1rem;
  }
}
.footer-text {
  text-align: left;
  size: 18px;
}
.footer-logo {
  float: left;
  padding: 0 20px 20px 0;
  height: 155px;
}
.list {
  list-style: none;
  display: inline-block;
  text-align: left;
}
.link {
  text-align: center;
}
.footer-button {
  background: linear-gradient(44.73deg, #7c7c7c 11.32%, #4e4e4e 92.96%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 190px;
  height: 55px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
  float: left;
}
.footer-button:hover {
  color: #ffffff;
}
/* home style  */
.new-showcase {
  background-color: #000;
  height: calc(100vh - 5.1rem);
  /* background-image: url('../src/images/Home/qubes.png'); */
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../src/images/Home/qubes.png');
  background-position: center;
  background-size: cover;
  object-fit: fill;
}
.showcase {
  background-color: #0a0909;
}
.showcase-1 {
  background-color: #ffffff !important;
}
.boxes {
  display: flex;
  justify-content: center;
}
.intro-btn2 {
  display: flex;
  column-gap: 10px;
  justify-content: flex-start;
  width: 100%;
}
.intro-btn2 a button {
  width: 100%;
}

.button2 {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 100px;
  width: 183px;
  height: 56px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}
.button2:hover {
  color: #ffffff;
}
.button3 {
  background: linear-gradient(44.73deg, #7c7c7c 11.32%, #4e4e4e 92.96%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 130px;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d7d7d8;
}

.place {
  padding-bottom: 70px;
}
.container-bg {
  /* background-image: url("/img/BG\ 1.svg"); */
  background-repeat: no-repeat;
  padding-left: 1em;
  padding-right: 1em;
}
.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #5a5a5a;
}
.card-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #303030;
  text-align: left;
}
.button4 {
  outline: none;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  background: linear-gradient(44.73deg, #7c7c7c 11.32%, #4e4e4e 92.96%);
  color: #fff;
  position: relative;
  bottom: 140px;
  left: 60px;
}
.button5 {
  outline: none;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  background: linear-gradient(44.73deg, #7c7c7c 11.32%, #4e4e4e 92.96%);
  color: #fff;
  position: relative;
  bottom: 179px;
  left: 2px;
}

.button6 {
  background-color: #652CB3;
  border-radius: 100px;
  padding: 1.2rem 3rem;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  color: #d7d7d8;
  align-items: flex-start;
  border: none !important;
}
.button6:hover {
  color: #ffffff;
  background-color: #8e57da;
}
@media (max-width: 1250px) {
  .button6 {
    padding: 1.2rem 2rem;
    font-size: 20px;
  }
}
@media (max-width: 1020px) {
  .button6 {
    padding: 1.2rem 1.7rem;
    font-size: 18px;
  }
}
@media (max-width: 370px) {
  .button6 {
    padding: 1.2rem 1.2rem;
    font-size: 16px;
  }
}
@media (max-width: 360px) {
  .button6 {
    padding: 1.2rem 1rem;
    font-size: 16px;
  }
  .new-showcase {
    padding-left: 1.3rem !important;
    padding-right: 1.3rem !important;
  }
}
.hero-banner-container {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  gap: 2rem;
}

.new-hero-tag{
  /* color: #808080; */
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  margin: 0;
  text-align: left;
}
.eco-1-title {
  font-style: normal;
  font-weight: bold;
  font-size: 3px;
  line-height: 41px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.content-img {
  display: grid;
  align-items: center;
  margin-left: 110px;
}
.intro-eco {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: left;
  padding-top: 50px;
  color: #5a5a5a;
}
.span-eco {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #5a5a5a;
}
.intro-eco-sentence {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  color: #000000;
}
.intro-eco-button {
  display: flex;
  column-gap: 10px;
  justify-content: first baseline;
}

.eco-button {
  background: #fff;
  border-radius: 40px;
  width: 130px;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
}

.eco-button1 {
  background: #fff;
  border-radius: 40px;
  width: 130px;
  height: 50px;
  /* padding-top: 15px; */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
}

.content-eco1-bg {
  background-color: #fff;
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}


.content-eco1-bg-2 {
  background-color: #000;
  padding-top: 75px !important;
}

@media (max-width: 680px) {
  .content-eco1-bg-2{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.content-eco-bg {
  background: linear-gradient(180deg, #f8f8f8 0%, rgba(241, 241, 241, 0) 100%);
}
.intro-eco-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: left;
  color: #5a5a5a;
}
.our-difference {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.card-body-child {
  position: absolute;
}

@media (min-width: 310px) {
  .intro-eco-2 {
    margin-left: none;
  }
}
@media (min-width: 576px) {
  .intro-eco-2 {
    margin-left: none;
  }
}
@media (min-width: 768px) {
  .intro-eco-2 {
    margin-left: none;
  }
}
@media (min-width: 992px) {
  .intro-eco-2 {
    margin-left: 0.5em;
  }
}
@media (min-width: 1200px) {
  .intro-eco-2 {
    margin-left: 1.4em;
  }
}
@media (min-width: 1400px) {
  .intro-eco-2 {
    margin-left: 3em;
  }
}
.span-eco-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #5a5a5a;
}
.intro-eco-card3 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #5a5a5a;
}
.intro-eco-card3-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #5a5a5a;
}
.eco-crd-1 {
  width: 100%;
}
.eco-horizontal-card {
  border-radius: 10px;
}
.eco-horizontal-card1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  color: #5a5a5a;
}
.eco-horizontal-card-txt-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #5a5a5a;
}
.group1 {
  display: flex;
  justify-content: space-evenly;
}
.group2 {
  display: flex;
  justify-content: space-evenly;
}
.group4 {
  display: flex;
  justify-content: space-evenly;
}
.card-group1 {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: 2em;
}
.card-img-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  object-fit: cover;
  height: 7vw;
}
.carousel-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.73px;
  color: #5a5a5a;
  text-align: left;
}
.slide_items {
  margin: 10em;
}
.Arrows {
  position: relative;
}
.Arrows .prev_arrow {
  position: absolute;
  bottom: 131px;
  right: 114px;
  width: 50px;
  height: 50px;
  background: #515151;
  border-radius: 50%;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.Arrows .next_arrow {
  position: absolute;
  bottom: 131px;
  left: -101px;
  width: 50px;
  height: 50px;
  background: #515151;
  border-radius: 50%;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.DAO-List {
  font-family: Rajdhani;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.box-animation-1 {
  position: absolute;
  float: left;
  width: 249.14px;
  height: 263.72px;
  z-index: 10;
}
.box-animation-2 {
  position: absolute;
  float: left;
  width: 59.8px;
  height: 63.37px;
  z-index: 10;
  margin-left: 317px;
  animation: box-animation-2 5s infinite;
}
@keyframes box-animation-2 {
  from {
    transform: rotate(-0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.box-animation-3 {
  position: absolute;
  float: left;
  width: 99.9px;
  height: 105.69px;
  z-index: 10;
  margin-top: 130px;
  margin-left: 290px;
  animation: box-animation-3 5s infinite;
}
@keyframes box-animation-3 {
  from {
    transform: rotate(-0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 310px) {
  .box-animation-1 {
    width: 5.5em;
    height: 6.5em;
  }
  .box-animation-2 {
    width: 35.8px;
    height: 38.37px;
    margin-left: 16em;
  }
  .box-animation-3 {
    width: 69.9px;
    height: 70.69px;
    margin-top: 120px;
    margin-left: 14em;
  }
}
@media (min-width: 360px) {
  .box-animation-1 {
    width: 5.5em;
    height: 6.5em;
  }
  .box-animation-2 {
    width: 35.8px;
    height: 38.37px;
    margin-left: 18em;
  }
  .box-animation-3 {
    width: 69.9px;
    height: 70.69px;
    margin-top: 120px;
    margin-left: 17em;
  }
}
@media (min-width: 576px) {
  .box-animation-1 {
    width: 125.14px;
    height: 125.72px;
  }
  .box-animation-2 {
    width: 35.8px;
    height: 38.37px;
    margin-left: 14em;
  }
  .box-animation-3 {
    width: 69.9px;
    height: 70.69px;
    margin-top: 120px;
    margin-left: 13em;
  }
}
@media (min-width: 768px) {
  .box-animation-1 {
    width: 175.14px;
    height: 175.72px;
  }
  .box-animation-2 {
    width: 35.8px;
    height: 38.37px;
  }
  .box-animation-3 {
    width: 69.9px;
    height: 70.69px;
  }
}
@media (min-width: 992px) {
  .box-animation-1 {
    width: 190.14px;
    height: 190.72px;
  }
  .box-animation-2 {
    width: 45.8px;
    height: 48.37px;
  }
  .box-animation-3 {
    width: 84.9px;
    height: 85.69px;
  }
}
@media (min-width: 1200px) {
  .box-animation-1 {
    width: 249.14px;
    height: 263.72px;
  }
  .box-animation-2 {
    width: 59.8px;
    height: 63.37px;
    margin-left: 317px;
  }
  .box-animation-3 {
    width: 99.9px;
    height: 105.69px;
    margin-top: 130px;
    margin-left: 290px;
  }
}
.right-box-animation-1 {
  position: absolute;
  float: right;
  width: 135.03px;
  height: 143.1px;
  z-index: 10;
  right: 26%;
}
.right-box-animation-2 {
  position: absolute;
  float: right;
  width: 59.8px;
  height: 63.37px;
  z-index: 10;
  right: 20.5%;
  margin-top: -40px;
  animation: box-animation-2 5s infinite;
}
@keyframes right-box-animation-2 {
  from {
    transform: rotate(-0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.right-box-animation-3 {
  position: absolute;
  float: right;
  width: 99.9px;
  height: 105.69px;
  z-index: 10;
  margin-top: 50px;
  right: 20%;
  animation: box-animation-2 5s infinite;
}
@keyframes right-box-animation-3 {
  from {
    transform: rotate(-0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 310px) {
  .right-box-animation-1 {
    width: 5.5em;
    height: 6.5em;
    right: 14%;
  }
  .right-box-animation-2 {
    width: 35.8px;
    height: 38.37px;
    right: 5.5%;
  }
  .right-box-animation-3 {
    width: 69.9px;
    height: 70.69px;
    right: 5%;
  }
}
@media (min-width: 576px) {
  .right-box-animation-1 {
    width: 100.03px;
    height: 108.1px;
    right: 14%;
  }
  .right-box-animation-2 {
    width: 35.8px;
    height: 38.37px;
    right: 5.5%;
  }
  .right-box-animation-3 {
    width: 69.9px;
    height: 70.69px;
    right: 5%;
  }
}
@media (min-width: 768px) {
  .right-box-animation-1 {
    width: 110.03px;
    height: 118.1px;
  }
  .right-box-animation-2 {
    width: 35.8px;
    height: 38.37px;
  }
  .right-box-animation-3 {
    width: 69.9px;
    height: 70.69px;
  }
}
@media (min-width: 992px) {
  .right-box-animation-1 {
    width: 135.03px;
    height: 143.1px;
  }
  .right-box-animation-2 {
    width: 45.8px;
    height: 48.37px;
  }
  .right-box-animation-3 {
    width: 84.9px;
    height: 85.69px;
  }
}
@media (min-width: 1200px) {
  .right-box-animation-1 {
    width: 135.03px;
    height: 143.1px;
    right: 26%;
  }
  .right-box-animation-2 {
    width: 59.8px;
    height: 63.37px;
    right: 20.5%;
  }
  .right-box-animation-3 {
    width: 99.9px;
    height: 105.69px;
    right: 20%;
  }
}
.left-box-2nd {
  position: absolute;
  float: left;
  width: 249.14px;
  height: 263.72px;
  z-index: 10;
  margin-top: -175px;
  left: 2.7%;
}
.right-box-2nd-animation-1 {
  position: absolute;
  float: right;
  width: 135.03px;
  height: 143.1px;
  z-index: 10;
  right: 16%;
}
.right-box-2nd-animation-2 {
  position: absolute;
  float: right;
  width: 59.8px;
  height: 63.37px;
  z-index: 10;
  right: 10.5%;
  margin-top: -40px;
  animation: box-animation-2 5s infinite;
}
@keyframes right-box-animation-2 {
  from {
    transform: rotate(-0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.right-box-2nd-animation-3 {
  position: absolute;
  float: right;
  width: 99.9px;
  height: 105.69px;
  z-index: 10;
  margin-top: 50px;
  right: 10%;
  animation: box-animation-2 5s infinite;
}
@keyframes right-box-animation-3 {
  from {
    transform: rotate(-0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 310px) {
  .left-box-2nd {
    width: 159.14px;
    height: 173.72px;
    margin-top: -124px;
  }
  .right-box-2nd-animation-1 {
    width: 80.03px;
    height: 88.1px;
  }
  .right-box-2nd-animation-2 {
    width: 34.8px;
    height: 38.37px;
  }
  .right-box-2nd-animation-3 {
    width: 69.9px;
    height: 65.69px;
  }
}
@media (min-width: 576px) {
  .left-box-2nd {
    width: 179.14px;
    height: 193.72px;
  }
  .right-box-2nd-animation-1 {
    width: 100.03px;
    height: 108.1px;
  }
  .right-box-2nd-animation-2 {
    width: 39.8px;
    height: 43.37px;
  }
  .right-box-2nd-animation-3 {
    width: 79.9px;
    height: 75.69px;
  }
}
@media (min-width: 768px) {
  .left-box-2nd {
    width: 209.14px;
    height: 223.72px;
  }
  .right-box-2nd-animation-1 {
    width: 120.03px;
    height: 128.1px;
  }
  .right-box-2nd-animation-2 {
    width: 49.8px;
    height: 53.37px;
  }
  .right-box-2nd-animation-3 {
    width: 94.9px;
    height: 90.69px;
  }
}
@media (min-width: 992px) {
  .left-box-2nd {
    width: 224.14px;
    height: 238.72px;
  }
  .right-box-2nd-animation-1 {
    width: 120.03px;
    height: 128.1px;
  }
  .right-box-2nd-animation-2 {
    width: 49.8px;
    height: 53.37px;
  }
  .right-box-2nd-animation-3 {
    width: 94.9px;
    height: 90.69px;
  }
}
@media (min-width: 1200px) {
  .left-box-2nd {
    width: 249.14px;
    height: 263.72px;
  }
  .right-box-2nd-animation-1 {
    width: 135.03px;
    height: 143.1px;
  }
  .right-box-2nd-animation-2 {
    width: 59.8px;
    height: 63.37px;
  }
  .right-box-2nd-animation-3 {
    width: 99.9px;
    height: 105.69px;
  }
}
.ai-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 135%;
  /* identical to box height, or 65px */

  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 580px) {
  .ai-title {
    font-size: 38px;
  }
}

.ai-para {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.roadmap-header-h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-top: 2em;
}
.list-group {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #5a5a5a;
}
.list-active-group {
  color: #2f2f2f;
  font-weight: 600;
}
.footbar-button-home1 {
  background: linear-gradient(44.73deg, #2a2929 11.32%, #2b2b2b 92.96%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 165px;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  text-align: center;
  color: #d7d7d8;
}
.footbar-button-home1:hover {
  color: #ffffff;
}
@media (min-width: 310px) {
  .footbar-button-home1 {
    font-size: 1.3em;
    line-height: 24px;
  }
}
@media (min-width: 576px) {
  .footbar-button-home1 {
    font-size: 1.3em;
    line-height: 24px;
  }
}
@media (min-width: 768px) {
  .footbar-button-home1 {
    font-size: 1.3em;
    line-height: 24px;
  }
}
@media (min-width: 992px) {
  .footbar-button-home1 {
    font-size: 1.3em;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  .footbar-button-home1 {
    font-size: 24px;
    line-height: 24px;
  }
}
.intro-sentence-homeapp {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  color: #000000;
}
.home-rectangle {
  height: 4px;
  width: 155px;
  background-color: #c4c4c4;
}
.text2 {
  display: flex;
  text-align: left;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}
/* pipeline style */
.pipeline-bg-icon-elipse1 {
  width: 800px;
}
.pipeline-bg {
  background-color: #e0e0e0;
  width: 100%;
  align-items: center;
}
.pipeline-cards-section {
  background-color: #e0e0e0;
}
.pipe-card-buttons {
  background-color: #ffffff;
  border-radius: 40px;
  width: 10em;
  height: 3em;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.pipe-card-button-class {
  display: flex;
  justify-content: flex-start;
}
.pipe-card-header {
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
  line-height: 36px;
  color: #222222;
  text-align: left;
}
.pipe-card-lead {
  font-style: normal;
  font-weight: normal;
  text-justify: inter-word;
  /* font-size: 14px;
  line-height: 18px; */
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.pipe-card {
  position: relative;
  padding-top: 1em;
  left: 3em;
}
.modal-title {
  height: 80px;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.modal-title2 {
  height: 180px;
  padding-left: 150px;
}
.content-img1 {
  position: relative;
  right: 700px;
  padding-top: 60px;
}
.content-img2 {
  padding-left: 60px;
}
.content-img3 {
  position: relative;
  right: 705px;
  padding-top: 110px;
}
.content-img4 {
  position: relative;
  right: 705px;
  padding-top: 50px;
}
.content-img5 {
  position: relative;
  right: 695px;
  padding-top: 120px;
}
.about-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.img1 {
  display: flex;
  justify-content: center;
}
.about-text1 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
}
.card2-list {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
}
.img2 {
  display: flex;
  justify-content: flex-start;
}
.square-2 {
  position: relative;
  top: -20px;
}
.img3 {
  display: flex;
  justify-content: center;
}
.square-3 {
  position: relative;
  top: 10px;
  right: 20px;
}
.trad-cons {
  text-align: left;
}
.home-cons {
  text-align: left;
}
.card-modal-4-type-row-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  align-items: center;
  color: #5a5a5a;
}
.trad-cons-content-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
  color: #5a5a5a;
}
.trad-cons-content-3 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
  color: #5a5a5a;
}
.trad-cons-content-4 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
  color: #5a5a5a;
}
.trad-cons-content-5 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
  color: #5a5a5a;
}
.trad-cons-content-6 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
  color: #5a5a5a;
}
.trad-cons-content-7 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
  color: #5a5a5a;
}
.home-cons-content-2-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
}
.home-cons-content-3-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
}
.home-cons-content-4-4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
}
.home-cons-content-5-5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
}
.home-cons-content-6-6 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
}
.home-cons-content-7-7 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: left;
}
.square-4 {
  position: relative;
  top: -20px;
  right: 30px;
}
/* transaction style  */
.bg-icon-transaction {
  position: relative;
  top: 35px;
  left: 95px;
  width: 700px;
}

.q-transactmap-div-title h2 {
  font-family: "Rajdhani", sans-serif;
  text-transform: capitalize;
  font-weight: 900;
  color: black;
}
.transaction-rectangle {
  height: 4px;
  width: 155px;
  background-color: #c4c4c4;
}
/* qube-tokenomics style  */
.bg-icon-tokenomics {
  position: relative;
  width: 600px;
}
.q-tokenomics-showcase-intro-lead {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #5a5a5a;
}
.q-tokenomics-intro-content {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #5a5a5a;
}
.intro-sentence-tokenomics {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #5a5a5a;
  text-align: left;
}
.q-tokenomics-bg-icon-2 {
  position: relative;
  left: 100px;
  width: 600px;
}
.q-tokenomics-bg-icon-3 {
  position: relative;
  width: 600px;
}
.q-tokenomics-caption-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #5a5a5a;
}
.q-tokenomics-type-row-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  align-items: center;
  color: #5a5a5a;
}
.q-tokenomics-caption-lead-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #5a5a5a;
}
.q-tokenomics-content-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-3 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-4 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-5 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-6 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-7 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-8 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-9 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-10 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
  text-align: center;
}
.q-tokenomics-content-11 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #5a5a5a;
}
.q-tokenomics-content-2-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-3-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-4-4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-5-5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-6-6 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-7-7 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-8-8 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-9-9 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-10-10 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: right;
  text-align: center;
}
.q-tokenomics-content-11-11 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: right;
}
.q-tokenomics-content-2-2-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-3-3-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}

.q-tokenomics-content-4-4-4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-5-5-5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-6-6-6 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-7-7-7 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-8-8-8 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-9-9-9 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: center;
}
.q-tokenomics-content-10-10-10 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: right;
  text-align: center;
}
.q-tokenomics-content-11-11-11 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  text-align: right;
}
.q-tokenomics-th-position {
  text-align: center;
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 125%;
  color: #ffffff;
}
/* The people style 
/* Carbon Styles */
.carbon-content-header {
  text-align: left;
  margin-top: 20px;
  font-family: "Rajdhani", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: #000000;
}
.carbon-content-message {
  text-align: left;
  font-family: "Rajdhani", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #5a5a5a;
}
.carbon-content-frame {
  top: 15px;
  position: relative;
}
.carbon-benefits-container {
  background-color: #e0e0e0;
}
.carbon-benefits-content {
  color: #5a5a5a;
  font-weight: bold;
  padding-top: 20px;
  text-align: left;
}
.carbon-benefits-content-head {
  color: #5a5a5a;
  font-weight: bold;
  text-align: left;
  font-size: 1.25em;
}

.carbon-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #5a5a5a;
  text-align: left;
}

.carbon-text-blog {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #5a5a5a;
  text-align: left;
}
/* FAQ Styles */
.faq-rectangle {
  height: 4px;
  width: 155px;
  background-color: #c4c4c4;
}
.faq-title {
  font-family: Rajdhani;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
}

.faq-title1 {
  font-family: Rajdhani;

  font-weight: 700;
  font-size: 32px;
}

.faqs-container {
  background-color: #f8f8f8;
}
.faqs-header {
  font-style: normal;
  font-weight: bold;
  color: #5a5a5a;
}
.faqs-text {
  font-style: normal;
  font-weight: 500;
  color: #5a5a5a;
}
.accordion-button {
  font-style: normal;
  color: #5a5a5a;
  font-size: 18px;
  line-height: 23px;
}

/* removes the bottom border and highlight */
.accordion-button:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.accordion-body {
  padding-top: 0;
}

/* svg from figma */
.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 15L12 9L6 15' stroke='%23A5A5A5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.accordion-text {
  font-style: normal;
  color: #5a5a5a;
  font-size: 18px;
  line-height: 23px;
}
/* Media  by Jade*/
@media (max-width: 360px) {
  /* footer button */
  .footer-button {
    float: none;
  }
  /* showcase 2 */
  .showcase-intro-2 {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 2em;
    padding-bottom: 7em;
  }
  /* Card*/
  .pipe-card {
    padding-top: 0;
    left: 0;
    margin-left: 0em !important;
  }
  .cards-section {
    padding-left: 0em !important;
    padding-top: 0em !important;
    padding-right: 0em !important;
  }
  .pipe-card1-img {
    padding-left: 0em !important;
    padding-top: 0em !important;
  }
}
@media (max-width: 536px) {
  /* footer button */
  .footer-button {
    float: none;
  }
  /* showcase 2 */
  .showcase-intro-2 {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 2em;
    padding-bottom: 7em;
  }
  .pipe-card {
    padding-top: 0;
    left: 0;
    margin-left: 0em !important;
  }
  .cards-section {
    padding-left: 0em !important;
    padding-top: 0em !important;
    padding-right: 0em !important;
  }
  .pipe-card1-img {
    padding-left: 0em !important;
    padding-top: 0em !important;
  }
}
@media (max-width: 768px) {
  /* footer button */
  .footer-button {
    float: none;
  }
  /* showcase 2 */
  .showcase-intro-2 {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 2em;
    padding-bottom: 7em;
  }
  .pipe-card {
    padding-top: 0;
    left: 0;
    margin-left: 0em !important;
  }
  .cards-section {
    padding-left: 0em !important;
    padding-top: 0em !important;
    padding-right: 0em !important;
  }
  .pipe-card1-img {
    padding-left: 0em !important;
    padding-top: 0em !important;
  }
}
@media (max-width: 992px) {
  /* nav item pipehome  */
  .nav-item-pipehome {
    transform: translateX(-12px);
  }
  /* footer logo */
  .footer-logo {
    float: none;
    padding: 0;
  }
  /* footer text */
  .footer-text {
    text-align: center;
  }
  /* footer links */
  .link {
    padding: 1rem;
  }
  /* footer button */
  .footer-button {
    float: none;
    /* width: 115px;
    height: 55px; */
  }
  /* showcase 2 */
  .showcase-intro-2 {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 2em;
    padding-bottom: 7em;
  }
  .pipe-card {
    padding-top: 0;
    left: 0;
    margin-left: 0em !important;
  }
  .showcase1 .container .d-sm-flex {
    flex-direction: column !important;
  }
  .showcase-intro,
  .showcase-intro-lead {
    text-align: center;
  }
  @media (min-width: 310px) {
    .showcase-intro-lead {
      text-align: left;
    }
  }
  @media (max-width: 1200px) {
    .card {
      width: 13em !important;
      height: auto;
    }
  }
  @media (min-width: 360px) {
    .card {
      width: 18em !important;
      height: auto;
    }
  }
  @media (min-width: 768px) {
    .card {
      width: 13em !important;
      height: auto;
    }
  }
  @media (min-width: 992px) {
    .card {
      width: 18em !important;
      height: auto;
    }
  }
  @media (min-width: 1200px) {
    .card {
      width: 17em !important;
      height: auto;
    }
  }
}
@media only screen and (max-width: 900px) and (min-width: 200px) {
  .cards-section .container .card {
    width: 100% !important;
  }
  .flex-modal-class {
    flex-direction: column-reverse !important;
  }
  .flex-modal-class img {
    width: 100% !important;
  }
  .flex-img-div {
    padding: 0 !important;
  }
  .flex-text-div {
    width: 100% !important;
    padding: 0 !important;
  }

  .showcase-hidden {
    display: none;
  }
}
@media (max-width: 1200px) {
}
@media (max-width: 1200px) {
}
.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}
@media (min-width: 768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: "";
    display: block;
    border-top: 0.25rem solid #e0e0e0;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: 0.3125rem;
  }
  .timeline-steps .timeline-step:not(:first-child):before {
    content: "";
    display: block;
    border-top: 0.25rem solid #e0e0e0;
    width: 3.8125rem;
    position: absolute;
    right: 7.5rem;
    top: 0.3125rem;
  }
  .timeline-steps .timeline-step:nth-child(2):not(:first-child):before {
    content: "";
    display: block;
    border-top: 0.25rem solid #2f2f2f;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: 0.3125rem;
  }
  .timeline-steps .timeline-step:nth-child(1):not(:last-child):after {
    content: "";
    display: block;
    border-top: 0.25rem solid #2f2f2f;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: 0.3125rem;
  }
  .timeline-steps .timeline-step:nth-child(1):not(:last-child):before {
    content: "";
    display: block;
    border-top: 0.25rem solid #2f2f2f;
    width: 6.46rem;
    position: absolute;
    left: 7.5rem;
    top: 0.3125rem;
  }
}
.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center;
}
.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #2f2f2f;
}
.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #2f2f2f;
  display: inline-block;
  height: 2rem;
  width: 2em;
  min-width: 2rem;
  border-radius: 2rem;
  opacity: 1;
}
.timeline-steps .timeline-step:nth-child(3) .timeline-content .inner-circle,
.timeline-steps .timeline-step:nth-child(4) .timeline-content .inner-circle,
.timeline-steps .timeline-step:nth-child(5) .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
}
.timeline-steps
  .timeline-step:nth-child(3)
  .timeline-content
  .inner-circle::before,
.timeline-steps
  .timeline-step:nth-child(4)
  .timeline-content
  .inner-circle::before,
.timeline-steps
  .timeline-step:nth-child(5)
  .timeline-content
  .inner-circle::before {
  content: "";
  background-color: #e0e0e0;
  display: inline-block;
  height: 2rem;
  width: 2em;
  min-width: 2rem;
  border-radius: 2rem;
  opacity: 1;
}
.list-group-item {
  text-align: center;
}
/* media for faqs */
@media (min-width: 310px) {
  .faqs-header {
    font-size: 1em;
    line-height: 1.25em;
  }
  .faqs-text {
    font-size: 1em;
    line-height: 1.25em;
  }
}
@media (min-width: 576px) {
  .faqs-header {
    font-size: 1em;
    line-height: 1.25em;
  }
  .faqs-text {
    font-size: 1em;
    line-height: 1.25em;
    padding-bottom: 5px;
  }
}
@media (min-width: 768px) {
  .faqs-header {
    font-size: 1.5em;
    line-height: 1.25em;
  }
  .faqs-text {
    font-size: 1.5em;
    line-height: 1.25em;
    padding-bottom: 8px;
  }
}
@media (min-width: 992px) {
  .faqs-header {
    font-size: 1.75em;
    line-height: 1.5em;
  }
  .faqs-text {
    font-size: 1.75em;
    line-height: 1.5em;
    padding-bottom: 10px;
  }
}
@media (min-width: 1200px) {
  .faqs-header {
    font-size: 28px;
    line-height: 36px;
  }
  .faqs-text {
    font-size: 28px;
    line-height: 36px;
    padding-bottom: 10px;
  }
}
/* media for carbon */
@media (min-width: 310px) {
  .carbon-content-header {
    font-size: 1em;
    line-height: 1.25em;
  }
  .carbon-text {
    font-size: 1em;
    line-height: 1.25em;
    padding-bottom: 1em;
  }
}
@media (min-width: 576px) {
  .carbon-content-header {
    font-size: 1em;
    line-height: 1.25em;
  }
  .carbon-text {
    font-size: 1em;
    line-height: 1.25em;
    padding-bottom: 1em;
  }
}
@media (min-width: 768px) {
  .carbon-content-header {
    font-size: 1em;
    line-height: 1.25em;
  }
  .carbon-text {
    font-size: 1em;
    line-height: 1.25em;
    padding-bottom: 1em;
  }
}
@media (min-width: 992px) {
  .carbon-content-header {
    font-size: 1em;
    line-height: 1.5em;
  }
  .carbon-text {
    font-size: 1em;
    line-height: 1.25em;
    padding-bottom: 1.25em;
  }
}
@media (min-width: 1200px) {
  .carbon-content-header {
    font-size: 28px;
    line-height: 36px;
  }
  .carbon-text {
    font-size: 18px;
    line-height: 23px;
  }
}
.content-info {
  display: flex;
}
@media (max-width: 310px) {
  .content-info {
    display: flex;
    flex-wrap: wrap;
  }
  .intro-sentence-homeapp {
    margin-top: 1rem;
  }
  .modal-header {
    margin: auto !important;
  }
}
@media (max-width: 576px) {
  .content-info {
    display: flex;
    flex-wrap: wrap;
  }
  .intro-sentence-homeapp {
    margin-top: 1rem;
  }
  .modal-header {
    margin: auto !important;
  }
}
@media (max-width: 768px) {
  .content-info {
    display: flex;
    flex-wrap: wrap;
  }
  .modal-header {
    margin: auto !important;
  }
}
@media (max-width: 992px) {
  .content-info {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 1200px) {
  .content-info {
    display: flex;
  }
}
@media (min-width: 310px) {
  .intro-sentence-homeapp {
    margin-top: 1rem;
  }
  .ai-content {
    display: flex !important;
    align-items: center;
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .intro-sentence-homeapp {
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .ai-content {
    display: flex !important;
    align-items: center;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .intro-sentence-homeapp {
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .ai-content {
    display: flex !important;
    align-items: center;
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .intro-sentence-homeapp {
    margin-left: 1rem;
  }
  .ai-content {
    align-items: center;
    flex-direction: row;
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .intro-sentence-homeapp {
    margin-top: 1rem;
    margin-left: 1rem;
  }
}
/*
@media (min-width: 310px) {
}
@media (min-width: 576px) {
}*/
@media (min-width: 992px) {
  .pipe-card-header {
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
  .pipe-card-lead {
    padding-left: 1rem;
  }
  .pipe-card-buttons-class {
    padding-left: 0.75rem;
  }
  .card-cointaner {
    margin-left: 3rem;
  }
}
@media (min-width: 1200px) {
  .pipe-card-header {
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
  .pipe-card-lead {
    padding-left: 1rem;
  }
  .pipe-card-buttons-class {
    padding-left: 0.75rem;
  }
  .card-cointaner {
    margin-left: 3rem;
  }
}
@media (min-width: 1400px) {
  .pipe-card-header {
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
  .pipe-card-lead {
    padding-left: 1rem;
  }
  .pipe-card-buttons-class {
    padding-left: 0.75rem;
  }
  .card-cointaner {
    margin-left: 3rem;
  }
}
.carousel-item img {
  height: 45vh;
  margin: auto !important;
  opacity: 0.3;
}
/* .carousel-item p {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  width: 466px !important;
  margin: auto;
} */
/* .carousel-item h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;

  color: #ffffff;
} */

.item-last {
  background-color: #000000 !important;
}
.showcase2 {
  background-color: #e0e0e0;
  padding-bottom: 2.5em;
}
.img-powered {
  width: 100% !important;
}
.img-poweredcon {
  margin: auto !important;
  padding: 0;
  width: 85% !important;
}
.people-title1 {
  font-size: 24px;
}
.pipeline-links {
  font-style: normal;
  font-weight: bold;
  /* font-size: 17px;
  line-height: 23px; */
  font-size: 18px;
  line-height: 23px;
  color: #5a5a5a;
  padding-left: 2px;
  text-decoration: underline;
}
.pipeline-links:hover {
  color: #5a5a5a;
}
/* Resource Style */
body {
  background-color: #ffffff !important;
}
.text3 {
  font-style: normal;
  font-weight: 700;
  font-size: 51px;
  text-transform: uppercase;
  color: #2f2f2f;
  padding-top: 5em !important;
}
.one-pager-text2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  padding-top: 0.5em;
}
.rectangle {
  background-color: #2f2f2f;
  width: 400px;
  height: 4px;
}
.under-dev-img {
  padding-top: 16em !important;
  padding-right: 5em !important;
  padding-bottom: 5em !important;
}
@media only screen and (max-width: 425px) {
}
.dropdown-bg {
  background-color: #2f2f2f;
}
.dropdown-btn {
  align-items: center;
}
.table-bottom-text {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 36px;
}
.carousel-caption {
  color: black !important;
  text-align: center !important;
  top: 25% !important;
  transform: translateY(-50%) !important;
  bottom: 50% !important;
}
#carouselExampleCaptions > .carousel-indicators [data-bs-target] {
  height: 10px !important;
  width: 10px !important;
  background-color: #9d9d9d;
}
#carouselExampleCaptions > .carousel-indicators > .active {
  background-color: white;
}
.footer-text-2 {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
@media (min-width: 310px) {
  .footer-text-2 {
    text-align: center;
  }
}
@media (min-width: 576px) {
  .footer-text-2 {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer-text-2 {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .footer-text-2 {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .footer-text-2 {
    padding-left: 15px;
    text-align: left;
  }
}
@media (min-width: 1400px) {
  .footer-text-2 {
    padding-left: 15px;
    text-align: left;
  }
}
.contact-send-button {
  background: linear-gradient(44.73deg, #7c7c7c 11.32%, #4e4e4e 92.96%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 100px;
  height: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  margin-top: 10px;
  color: #d7d7d8;
}
.button-container {
  width: 50%;
}
.textarea-text {
  width: 100%;
}
@media (min-width: 310px) {
  .text-container {
    width: 100%;
  }
  .contact-send-button {
    float: none;
  }
}
@media (min-width: 768px) {
  .text-container {
    width: 49%;
  }
  .contact-send-button {
    float: right;
  }
}
.button-contact {
  visibility: hidden;
  position: fixed;
  height: 60px;
  bottom: 60px;
}
.button-contact-img {
  margin-top: 22px;
}
@media (min-width: 310px) {
  .button-contact-img {
    width: 6rem;
  }
  .button-contact {
    right: 1%;
  }
}
@media (min-width: 360px) {
  .button-contact-img {
    width: 6rem;
  }
  .button-contact {
    right: 1%;
  }
}
@media (min-width: 576px) {
  .button-contact-img {
    width: 8rem;
  }
  .button-contact {
    right: 1%;
  }
}
@media (min-width: 768px) {
  .button-contact {
    right: 1%;
  }
}
@media (min-width: 2190px) {
  .button-contact {
    right: 1%;
  }
}
.details {
  font-family: "Rajdhani", sans-serif;
  font-style: normal;
  text-transform: uppercase;
}

.faqs-th-position {
  text-align: start;
}

.faq-table-content-1-1 {
  font-family: "Rajdhani", sans-serif;
  font-style: normal;

  font-size: 18px;
  line-height: 23px;
  align-items: center;
}

.faq-table-content-1-2 {
  font-family: "Rajdhani", sans-serif;
  font-style: normal;

  font-size: 18px;
  line-height: 23px;
  align-items: center;
}

.faq-table-content-1 {
  font-family: "Rajdhani", sans-serif;
  font-style: normal;

  font-size: 18px;
  line-height: 23px;
  align-items: center;
}

.faq-table-bottom-text {
  font-family: "Rajdhani", sans-serif;
  font-style: normal;

  font-size: 19px;
  line-height: 36px;
}

.faqs-image-1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.dropdown-menu {
  background-color: #3f3f4b;
  text-align: center;
}
.dropdown-item {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  cursor: pointer;
  /* or 24px */

  text-transform: uppercase;

  /* Primary/5 */

  color: #ffffff;
}
.people-title {
  font-family: Rajdhani;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
}
.title-modal {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
}
.people-card-title-name {
  font-weight: 700;
  font-size: 1.2em;
}
@media (min-width: 310px) {
  .showcase-intro-lead-carbon {
    font-size: 1em;
    line-height: 1.25em;
  }
  .intro-sentence-homeapp {
    font-size: 1em;
    line-height: 1.25em;
  }
  .intro-eco-sentence {
    font-size: 1em;
    line-height: 1.25em;
    width: 100% !important;
  }
  .showcase-intro-lead {
    font-size: 1em;
    line-height: 1.25em;
  }
  .q-tokenomics-showcase-intro-lead {
    font-size: 1em;
    line-height: 1.25em;
  }
  .people-title1 {
    font-size: 1em;
  }
  .accordion-text {
    font-size: 1em;
    line-height: 1.25em;
  }
}
@media (min-width: 768px) {
  .showcase-intro-lead-carbon {
    font-size: 18px;
    line-height: 23px;
  }
  .intro-sentence-homeapp {
    font-size: 18px;
    line-height: 23px;
  }
  .intro-eco-sentence {
    font-size: 18px;
    line-height: 23px;
    width: 50% !important;
  }
  .showcase-intro-lead {
    font-size: 18px;
    line-height: 23px;
  }
  .q-tokenomics-showcase-intro-lead {
    font-size: 18px;
    line-height: 23px;
  }
  .people-title1 {
    font-size: 23px;
  }
  .accordion-text {
    font-size: 18px;
    line-height: 23px;
  }
}
.wrap-img-esg1 {
  background-image: url("../src/images/ESG/Graphic\ Break\ 1.jpg");
  min-height: 50vh;

  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat, no-repeat;
}
.wrap-img-esg2 {
  background-image: url("../src/images/ESG/Graphic\ Break\ 2.jpg");
  min-height: 50vh;
  width: 100%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat, no-repeat;
}

.news-imgPR {
  margin-top: 20px;
}

.blog-image {
  height: 80vh;
  width: 80%;
}
.blog-image2 {
  width: 100%;
  height: 100%;
}

.blog-image1 {
  width: 50%;
  height: auto;
}
.img-breaker {
  width: 100%;
}
.blog-link {
  color: #5a5a5a;
  font-weight: normal;
}

.blog-link:hover {
  color: #5a5a5a;
}

@media only screen and (max-width: 900px) and (min-width: 200px) {
  .wrap-img-esg1 {
    min-height: 30vh;
  }
  .wrap-img-esg2 {
    min-height: 30vh;
  }

  .news-imgPR {
    height: 30vh;
  }

  .blog-image {
    height: 30vh;
  }

  .blog-image1 {
    width: 100%;
  }
}
.carbon-text-list {
  list-style: none;
}
.container-content-carbon {
  text-align: left;
  height: 100vh;
}
.me-auto {
  margin-right: 0 !important;
}
.about-nav {
  display: flex !important;
}

@media (min-width: 310px) {
  .about-nav {
    display: flex !important;
    justify-content: center;
  }
  .reslink {
    margin-left: 2rem;
  }
}
@media (min-width: 768px) {
  .about-nav {
    display: flex !important;
    justify-content: center;
  }
  .reslink {
    margin-left: 0;
  }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #d7d7d8 !important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  background: #3f3f4b;
}
.spacing {
  margin: 0 27px;
}
.small-text {
  font-size: 13px !important;
  font-weight: bold !important;
  text-align: center;
}
.exchange {
  width: 100%;
}
.exchange-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;

  color: #000000;
}
.exchang-title-2 {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.exchange-container {
  display: flex;
  justify-content: center;
}
.first-exchange-container {
  background: linear-gradient(125.75deg, #9945ff 0%, #14f195 98.3%);
  border-radius: 10px 0px 0px 10px;
  width: 513px;
  height: 259px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.second-exchange-container {
  background: linear-gradient(126.88deg, #010101 0%, #5e5e63 100%);
  border-radius: 0px 10px 10px 0px;
  width: 512px;
  height: 259px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.exchange-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  text-align: center;

  color: #ffffff;
}
.exchange-arrow {
  position: absolute;
  z-index: 10;
  top: 37%;
}
.exchange-input {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #69696b;
  width: 211px;
  height: 32px;
}

@media (min-width: 310px) {
  .first-exchange-container {
    width: 320px;
    border-radius: 10px 10px 0px 0px;
  }
  .second-exchange-container {
    width: 320px;
    border-radius: 0px 0px 10px 10px;
  }
  .exchange-container {
    flex-direction: column;
  }
}

@media (min-width: 390px) {
  .first-exchange-container {
    width: 350px;
    border-radius: 10px 10px 0px 0px;
  }
  .second-exchange-container {
    width: 350px;
    border-radius: 0px 0px 10px 10px;
  }
  .exchange-container {
    flex-direction: column;
  }
}

@media (min-width: 420px) {
  .first-exchange-container {
    width: 513px;
    border-radius: 10px 0px 0px 10px;
  }

  .second-exchange-container {
    width: 513px;
    border-radius: 0px 10px 10px 0px;
  }

  .exchange-container {
    flex-direction: row;
  }
}
@media (min-width: 310px) {
  .sliders-view {
    display: none !important;
  }
}

@media (min-width: 720px) {
  .sliders-view {
    display: block !important;
  }
}
.ml-4 {
  margin-left: 2rem !important;
}
.hq-logo-content {
  width: 15%;
}

.card-image-blog {
  border-top-left-radius: calc(0.25rem - 1px) !important;
  border-top-right-radius: calc(0.25rem - 1px) !important;
}
.card-img-blog-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  object-fit: cover;
  height: 15vw;
}
.card-blog {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  /* border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; */
  height: 100%;
}
.item-link-selection:hover {
  color: #181818;
}

.link-underline {
  text-decoration: none;
  color: #000000;
}
.link-underline:hover {
  color: #292929;
}
.product-link {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 41px;
  text-transform: uppercase;
  color: #000000;
}

.new-footer-headline {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  text-transform: uppercase;

  color: #ffffff;
}

.new-footer-headline-2 {
  /* Headline/18px/Bold */

  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  text-transform: uppercase;

  color: #ffffff;
}
.new-footer-text {
  /* Headline/18px/Bold */

  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  text-transform: uppercase;

  /* Primary/5 */

  color: #ffffff;
}
.bg-black {
  background-color: #0a0909;
}

@media (min-width: 310px) {
  .footerResponsive {
    display: grid;
  }
  .footerWeb {
    display: none;
  }
}

@media (min-width: 990px) {
  .footerResponsive {
    display: none;
  }
  .footerWeb {
    display: grid;
  }
}
.a-no-design {
  color: #ffffff;
  text-decoration: none;
}
.a-no-design img {
  padding: 5px;
  cursor: pointer;
  border-radius: 100%;
  border-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  background-color: rgb(255, 255, 255);
  position: relative;
  color: rgb(0, 0, 0);
  height: 1.75rem !important;
  width: 1.75rem !important;
}
.a-no-design:hover {
  color: #dddddd;
}
.new-dropdown-arrow {
  width: 13px;
  height: 13px;
}
.new-white-button {
  background: #f4f4f8;
  border-radius: 100px;
  width: 185px;
  height: 56px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #040f24;
}

@media (max-width: 1200px) {
  .new-white-button {
    width: 165px;
    height: 56px;
  }
}
.mission-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 125%;
  /* identical to box height, or 70px */

  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;

  color: #ffffff;
}
.mission-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  width: 466px !important;
  margin: auto;
  /* or 33px */

  text-align: center;

  color: #ffffff;
}
.beliefs-bg {
  background-color: #0a0909;
}
.beliefs-content {
  width: 863px;
}

.beliefs-header {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.375rem;
  line-height: 125%;
  /* identical to box height, or 70px */

  letter-spacing: 4px;
  text-transform: uppercase;

  color: #ffffff;
}
.beliefs-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 150%;
  /* or 33px */

  color: #ffffff;
}
.img-z-index {
  display: flex;
  z-index: 1;
  justify-content: space-around;
}
.homepage-new-img {
  position: absolute;
  transform: translateY(-72px);
}
.context-bg {
  background: #000;
}
.mt-50 {
  margin-top: 22rem !important;
}
.context-content {
  padding-left: 6rem;
}
.context-header {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.375rem;
  line-height: 125%;
  /* identical to box height, or 80px */

  letter-spacing: 4px;
  text-transform: uppercase;

  color: #ffffff;
}
.content-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 160%;
  /* or 29px */

  color: #ffffff;
}
.SED-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 125%;
  /* or 60px */

  letter-spacing: 2px;
  text-transform: uppercase;

  color: #fff;
}
.SED-content {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 150%;
  /* or 33px */
  background-color: #28282d;
  color: #fff;
}
.TA-bg {
  background: #0a0909;
}
.TA-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 125%;
  /* or 70px */

  letter-spacing: 4px;
  text-transform: uppercase;

  color: #ffffff;
}
.ta-frame {
  width: 738px;
  height: 129px;
  padding: 24px 34px;
  background: linear-gradient(
    94.27deg,
    rgba(28, 36, 51, 0.46) 7.11%,
    rgba(28, 36, 51, 0.71) 31.3%,
    rgba(28, 36, 51, 0.42) 101.91%
  );
  border-radius: 8px;
}
.ta-frame-2 {
  width: 738px;
  height: 75px;
  padding: 24px 34px;
  background: linear-gradient(
    94.27deg,
    rgba(28, 36, 51, 0.46) 7.11%,
    rgba(28, 36, 51, 0.71) 31.3%,
    rgba(28, 36, 51, 0.42) 101.91%
  );
  border-radius: 8px;
}
.ta-frame-3 {
  width: 738px;
  height: 102px;
  padding: 24px 34px;
  background: linear-gradient(
    94.27deg,
    rgba(28, 36, 51, 0.46) 7.11%,
    rgba(28, 36, 51, 0.71) 31.3%,
    rgba(28, 36, 51, 0.42) 101.91%
  );
  border-radius: 8px;
}

.ta-frame-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 150%;
  /* or 27px */

  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mt-7 {
  margin-top: 5rem;
}
.new-footbar-bg {
  background: #3f3f4b;
}
.new-footbar-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 135%;
  /* or 43px */

  text-transform: uppercase;
  color: #ffffff;
}
.new-footbar-button {
  background: #f4f4f8;
  /* box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 40px;
  width: 270px;
  height: 56px;
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  text-transform: uppercase;

  /* Primary/1 */

  color: #040f24;
}
.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
.state-of-play-header {
  /* Headline/56px/Bold */

  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 120%;
  /* or 67px */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.state-of-play-header-2 {
  /* Headline/56px/Bold */

  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 2.563rem;
  line-height: 120%;
  /* or 67px */

  text-align: center;
  /* text-transform: uppercase; */

  color: #ffffff;
}

.state-of-play-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 150%;
  /* or 33px */

  text-align: center;

  color: #ffffff;
}
.state-of-play-paragraph-2 {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  /* or 48px */

  text-align: center;
}
.qube-wrap {
  background: #3f3f4b;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
}
.card-body {
  padding: 1rem 0 !important;
}
.new-card-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 140%;
  /* identical to box height, or 34px */

  /* Black */

  color: #000000;
}
.new-card-desc {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  /* or 24px */

  /* Black */

  color: #000000;
}

.read-more-link {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 135%;
  text-decoration: none;
  /* or 24px */

  text-transform: uppercase;

  color: #1c2433;
}
.read-more-link:hover {
  color: #263044;
}
.blog-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 125%;
  /* identical to box height, or 60px */

  text-transform: uppercase;

  color: #0a0909;
}
.new-black-button {
  border: 1px solid #ffffff;
  border-radius: 100px;
  width: 185px;
  height: 56px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
}
.new-black-button:hover {
  color: rgb(235, 235, 235);
}
@media (max-width: 1200px) {
  .new-black-button {
    width: 165px;
    height: 56px;
  }
}

.tokenomics-sub-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 150%;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  width: 654px !important;
  margin: auto;
  text-align: center;
  color: #ffffff;
}
.bg-dark-tokenomics {
  background: #0a0909;
}
.tokenomics-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.188rem;
  line-height: 125%;
  /* or 70px */

  text-transform: uppercase;

  color: #ffffff;
}
.tokenomics-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 150%;
  /* or 27px */

  color: #ffffff;
}
.tokenomics-title-2 {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 2.688rem;
  line-height: 125%;
  /* or 60px */

  text-align: center;
  text-transform: uppercase;
  color: #0a0909;
}
.bg-tokenomics-table {
  background: #f4f4f8;
}
.tokenomics-coin-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 125%;
  /* identical to box height, or 40px */

  text-transform: uppercase;
  color: #ffffff;
}
.tokenomics-table-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 2.688rem;
  line-height: 125%;
  /* identical to box height, or 60px */

  text-align: center;
  text-transform: uppercase;

  color: #0a0909;
}
.tokenomics-table-td {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 125%;
  /* identical to box height, or 22px */

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}
.tokenomics-table-active {
  background: #ffffff;
}
.initial-coin-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  color: #ffffff;
}
.initial-coin-bottom-text {
  background: linear-gradient(
    94.27deg,
    rgba(28, 36, 51, 0.46) 7.11%,
    rgba(28, 36, 51, 0.71) 31.3%,
    rgba(28, 36, 51, 0.42) 101.91%
  );
  border-radius: 4px;
}
.carbon-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 125%;
  /* or 60px */

  text-transform: uppercase;

  color: #ffffff;
}
.carbon-new-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 150%;
  /* or 27px */
  color: #ffffff;
  padding-right: 3rem !important;
  margin-right: 3rem !important;
}
.bg-black-2 {
  background: #141923;
}
.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #141923;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.carbon-accordion-button {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #ffffff;
}
.carbon-accordion-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  color: #e1e1e1;
}
.accordion-item-border {
  overflow: hidden;
  background-color: #141923 !important;
  border: 2px solid #333e58 !important;
  border-radius: 8px !important;
}

.carbon-3rd-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 125%;
  /* or 60px */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}
.our-philosophy-sub-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */

  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #abbdd8;
}
.our-philosophy-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 120%;
  /* or 67px */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}
.bg-white {
  background: #f4f4f8;
}
.our-philosophy-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  /* Black */

  color: #000000;
}
.yt-z-index {
  z-index: 5;
}
.yt-z-index iframe {
  margin-left: 9.5rem;
  display: flex;
  z-index: 5;
  width: 1000px;
  height: 590px;
  border-radius: 24px;
  justify-content: space-around;
  transform: translateY(-80px);
}
.our-philosophy-sub-title-2 {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  /* identical to box height, or 29px */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #abbdd8;
}
.our-philosophy-contetn-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 125%;
  /* or 70px */

  text-transform: uppercase;

  color: #ffffff;
}
.our-philisophy-content {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 150%;
  /* or 27px */

  color: #ffffff;
}
.carousel-item .bg {
  height: auto !important;
  margin: auto !important;
  opacity: 0.15 !important;
}
.our-philosophy-mini-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  /* identical to box height, or 29px */

  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #abbdd8;
}
.our-philosophy-mini-title-2 {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 125%;
  /* identical to box height, or 40px */

  text-align: center;
  text-transform: uppercase;

  color: #c9c9c9;
}
.philo-img {
  width: 56px;
  height: 56px !important;
  opacity: 1 !important;
}
.last-content-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
  /* or 27px */

  text-align: center;

  color: #ffffff;
}
.dao-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 125%;
  /* identical to box height, or 70px */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}
.dao-content-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 125%;
  /* or 70px */

  text-transform: uppercase;

  color: #ffffff;
}
.dao-content-sub-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  /* identical to box height, or 29px */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #abbdd8;
}
.dao-content {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
  /* or 27px */

  color: #ffffff;
}
.dao-learn-more {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 135%;
  /* or 24px */

  text-transform: uppercase;

  /* Primary/5 */

  color: #ffffff;
}
.mission-showcase {
  background-image: url(/src/images/NewImages/Background.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #0a0909;
}
.tokenomics-showcase {
  background-image: url(/src/images/NewImages/tokenomics.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #0a0909;
}
.philosophy-showcase {
  background-image: url(/src/images/NewImages/newPhilosophy/last-conent-bg.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #0a0909;
}
.tokenomics-container-bg {
  background-repeat: no-repeat;
  padding-left: 1em;
  padding-right: 1em;
  padding: 6% !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5));
}
.philosophy-container-bg {
  background-repeat: no-repeat;
  padding-left: 1em;
  padding-right: 1em;
  padding: 8% !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5));
}
.mission-container-bg {
  background-repeat: no-repeat;
  padding-left: 1em;
  padding-right: 1em;
  padding: 8% !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5));
}
.read-more-text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  cursor: pointer;
  /* or 24px */

  text-transform: uppercase;

  /* Primary/5 */

  color: #ffffff;
}
@media (min-width: 350px) {
  .context-responsive {
    display: block;
  }
  .context-desktop {
    display: none;
  }
  .sed-content {
    flex-direction: column;
  }
  .initial2-responsive {
    display: block;
  }
  .initial2-web {
    display: none;
  }
  .carbon-content-web {
    display: none !important;
  }
  .carbon-content-responsive {
    display: block !important;
  }
  .dao-responsive {
    display: block;
  }
  .dao-web {
    display: none;
  }
  .reponsive-logo {
    display: block;
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
}
@media (min-width: 768px) {
  .context-responsive {
    display: none;
  }
  .context-desktop {
    display: block;
  }
  .sed-content {
    flex-direction: column;
  }
  .initial2-responsive {
    display: none;
  }
  .initial2-web {
    display: block;
  }
  .carbon-content-web {
    display: block !important;
  }
  .carbon-content-responsive {
    display: none !important;
  }
  .philo-align-center {
    align-items: center;
  }
}
@media (min-width: 1020px) {
  .dao-responsive {
    display: none;
  }
  .dao-web {
    display: block;
  }
}

@media (min-width: 1140px) {
  .reponsive-logo {
    display: none;
  }
}

.nav-new-buttons {
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: row;
  display: flex;
}

.nav-new-buttons-responsive {
  display: none;
}


@media only screen and (min-width: 350px) and (max-width: 464px) {
  .dropdown-menu {
    width: 100%;
    margin-bottom: 1rem;
  }
  .nav-new-buttons-responsive {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 1rem;
  }
  .mission-paragraph {
    width: auto;
    font-size: 1rem;
  }
  .beliefs-content {
    width: 100%;
  }
  .mission-paragraph {
    width: 100% !important;
  }
  .ta-frame,
  .ta-frame-2,
  .ta-frame-3 {
    width: 100%;
  }
  .mission-text {
    font-size: 2.5rem;
  }
  .beliefs-header {
    font-size: 2.375rem;
  }
  .beliefs-paragraph {
    font-size: 1rem;
  }
  .homepage-new-img {
    width: 90% !important;
  }
  .mt-50 {
    margin-top: 3rem !important;
  }
  .context-header {
    font-size: 2.375rem;
  }
  .content-paragraph {
    font-size: 1rem;
  }
  .SED-title {
    font-size: 2rem;
  }
  .content-eco1-bg {
    padding-top: 1rem !important;
  }
  .SED-content {
    margin-bottom: 1rem !important;
  }
  .TA-title {
    font-size: 2rem;
  }
  .ta-frame {
    height: auto;
  }
  .ta-frame-2 {
    height: auto;
  }
  .ta-frame-3 {
    height: auto;
  }
  .p-5 {
    padding: 2rem !important;
  }
  .new-footbar-text {
    font-size: 1.5rem;
    text-align: center;
  }
  .state-of-play-header {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  .px-5 {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important;
  }
  .mx-5 {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important;
  }
  .state-of-play-paragraph {
    font-size: 1rem;
  }
  .state-of-play-paragraph-2 {
    font-size: 1rem;
  }
  .state-of-play-header-2 {
    font-size: 1.563rem;
  }
  .card-img-blog-top {
    height: auto;
  }
  .blog-title {
    font-size: 2rem;
  }
  .new-card-title {
    font-size: 1.25rem;
  }
  .read-more-link {
    font-size: 1rem;
  }
  /* ---- */
  .tokenomics-sub-title {
    font-size: 1rem;
    width: auto !important;
  }
  .tokenomics-title {
    font-size: 2rem;
  }
  .tokenomics-paragraph {
    font-size: 1rem;
  }
  .tokenomics-title-2 {
    font-size: 1.688rem;
  }
  .py-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .initial-coin-responsive {
    flex-direction: column;
    text-align: start;
  }
  .tokenomics-table-title {
    font-size: 1.688rem;
  }
  .q-tokenomics-th-position {
    font-size: 1.3rem;
  }
  .tokenomics-table-td {
    font-size: 1.1rem;
  }
  .carbon-title {
    font-size: 2rem !important;
  }
  .pt-5 {
    padding-top: 1rem !important;
  }
  .carbon-new-text {
    padding-right: 0 !important;
    margin-right: 0 !important;
    font-size: 1rem;
  }
  .carbon-responsive-display {
    flex-direction: column;
  }
  .carbon-3rd-title {
    font-size: 2rem;
  }
  .mt-5 {
    margin-top: 1rem !important;
  }
  .our-philosophy-title {
    font-size: 2.5rem;
  }
  .our-philosophy-sub-title-2 {
    font-size: 1.2rem;
  }
  .philosophy-content-responsive {
    flex-direction: column;
  }
  .our-philosophy-contetn-title {
    font-size: 2.5rem;
  }
  .our-philisophy-content {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .philosophy-last-content {
    flex-direction: column !important;
    align-items: center;
  }
  .philo-align-center {
    align-items: center;
  }
  .our-philosophy-mini-title {
    font-size: 1.25rem;
  }
  .our-philosophy-mini-title-2 {
    font-size: 1.5rem;
  }
  .last-content-text {
    font-size: 1rem;
  }
  .dao-content-title {
    font-size: 2.5rem;
  }
  .dao-content-sub-title {
    font-size: 1rem;
  }
  .dao-content {
    font-size: 1rem;
  }
  .dao-learn-more {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 465px) and (max-width: 768px) {
  .dropdown-menu {
    width: 105%;
    margin-bottom: 1rem;
  }
  .nav-new-buttons-responsive {
    margin-top: 1rem;
    display: flex;
  }
  .mission-paragraph {
    width: auto;
  }
  .beliefs-content {
    width: 100%;
  }
  .mission-paragraph {
    width: 100% !important;
  }
  .ta-frame,
  .ta-frame-2,
  .ta-frame-3 {
    width: 100%;
  }
  .mission-text {
    font-size: 2.5rem;
  }
  .beliefs-header {
    font-size: 2.375rem;
  }
  .beliefs-paragraph {
    font-size: 1rem;
  }
  .homepage-new-img {
    width: 90% !important;
  }
  .mt-50 {
    margin-top: 3rem !important;
  }
  .context-header {
    font-size: 2.375rem;
  }
  .content-paragraph {
    font-size: 1rem;
  }
  .SED-title {
    font-size: 2rem;
  }
  .content-eco1-bg {
    padding-top: 1rem !important;
  }
  .SED-content {
    margin-bottom: 1rem !important;
  }
  .TA-title {
    font-size: 2rem;
  }
  .ta-frame {
    height: auto;
  }
  .ta-frame-2 {
    height: auto;
  }
  .ta-frame-3 {
    height: auto;
  }
  .p-5 {
    padding: 2rem !important;
  }
  .new-footbar-text {
    font-size: 1.5rem;
    text-align: center;
  }
  .state-of-play-header {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  .px-5 {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important;
  }
  .mx-5 {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important;
  }
  .state-of-play-paragraph {
    font-size: 1rem;
  }
  .state-of-play-paragraph-2 {
    font-size: 1rem;
  }
  .state-of-play-header-2 {
    font-size: 1.563rem;
  }
  .card-img-blog-top {
    height: auto;
  }
  .blog-title {
    font-size: 2rem;
  }
  .new-card-title {
    font-size: 1.25rem;
  }
  .read-more-link {
    font-size: 1rem;
  }

  .tokenomics-sub-title {
    font-size: 1rem;
    width: auto !important;
  }
  .tokenomics-title {
    font-size: 2rem;
  }
  .tokenomics-paragraph {
    font-size: 1rem;
  }
  .tokenomics-title-2 {
    font-size: 1.688rem;
  }
  .py-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .initial-coin-responsive {
    flex-direction: column;
    text-align: start;
  }
  .tokenomics-table-title {
    font-size: 1.688rem;
  }
  .q-tokenomics-th-position {
    font-size: 1.3rem;
  }
  .tokenomics-table-td {
    font-size: 1.1rem;
  }
  .carbon-title {
    font-size: 2rem !important;
  }
  .pt-5 {
    padding-top: 1rem !important;
  }
  .carbon-new-text {
    padding-right: 0 !important;
    margin-right: 0 !important;
    font-size: 1rem;
  }
  .carbon-responsive-display {
    flex-direction: column;
  }
  .carbon-3rd-title {
    font-size: 2rem;
  }
  .mt-5 {
    margin-top: 1rem !important;
  }
  .our-philosophy-title {
    font-size: 2.5rem;
  }
  .our-philosophy-sub-title-2 {
    font-size: 1.2rem;
  }
  .philosophy-content-responsive {
    flex-direction: column;
  }
  .our-philosophy-contetn-title {
    font-size: 2.5rem;
  }
  .our-philisophy-content {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .philosophy-last-content {
    flex-direction: column !important;
    align-items: center;
  }
  .philo-align-center {
    align-items: center;
  }
  .our-philosophy-mini-title {
    font-size: 1.25rem;
  }
  .our-philosophy-mini-title-2 {
    font-size: 1.5rem;
  }
  .last-content-text {
    font-size: 1rem;
  }
  .dao-content-title {
    font-size: 2.5rem;
  }
  .dao-content-sub-title {
    font-size: 1rem;
  }
  .dao-content {
    font-size: 1rem;
  }
  .dao-learn-more {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 988px) {
  .dropdown-menu {
    width: 105%;
    margin-bottom: 1rem;
  }
  .nav-new-buttons-responsive {
    margin-top: 1rem;
    display: flex;
  }
  .context-responsive {
    display: none;
  }
  .context-desktop {
    display: block;
  }
  .beliefs-content {
    width: 863px;
  }
  .mission-paragraph {
    width: 466px !important;
  }
}

@media (min-width: 350px) {
  .yt-z-index iframe {
    margin-left: 1.5rem;
    width: 300px;
    height: 150px;
  }
}
@media (min-width: 420px) {
  .yt-z-index iframe {
    margin-left: 1.5rem;
    width: 350px;
    height: 200px;
  }
}
@media (min-width: 525px) {
  .yt-z-index iframe {
    margin-left: 2.5rem;
    width: 425px;
    height: 250px;
  }
}
@media (min-width: 650px) {
  .yt-z-index iframe {
    margin-left: -1rem;
    width: 550px;
    height: 300px;
  }
}

@media (min-width: 768px) {
  .yt-z-index iframe {
    margin-left: 2.5rem;
    width: 615px;
    height: 250px;
  }
}
@media (min-width: 970px) {
}
@media (min-width: 1040px) {
  .yt-z-index iframe {
    margin-left: 2rem;
    width: 867px;
    height: 560px;
  }
}
@media (min-width: 1200px) {
  .yt-z-index iframe {
    margin-left: 9.5rem;
    width: 1000px;
    height: 590px;
  }
}

@media only screen and (min-width: 992px) {
  .showcase1 table {
    margin-top: 2.5rem;
  }

  .showcase1 table thead tr th {
    font-size: 1.3rem;
    padding: 0.65rem;
    font-weight: 500;
  }

  .showcase1 table tr th:first-child {
    width: 37%;
  }

  .showcase1 table tr td {
    padding-left: 1rem;
  }
}

@media only screen and (min-width: 1200px) {
  .showcase1 table tr th:first-child {
    width: 30%;
  }
}
@media (max-width: 600px) {
  .showcase1 table.table.w-75 {
    width: 100% !important;
  }
  .tokenomics-table-td {
    font-size: 0.77rem;
  }
  .q-tokenomics-th-position {
    font-size: 0.77rem;
    padding: 6px;
  }
  .tokenomics-table-title {
    font-size: 1.88rem;
    width: 95%;
    margin-left: 2.5%;
  }
  .table > :not(caption) > * > * {
    padding: 0.75rem 0.5rem;
  }
}

@media (min-width: 600px) {
  .showcase1 table.table.w-75 {
    width: 100% !important;
  }
  .showcase1 table tr th:first-child {
    width: 40%;
  }
  .q-tokenomics-th-position {
    font-size: 0.8rem;
    padding: 6px;
  }
  .tokenomics-table-title {
    font-size: 1.88rem;
    width: 95%;
    margin-left: 2.5%;
  }
  .tokenomics-table-td {
    font-size: 0.8rem;
  }
  .table > :not(caption) > * > * {
    padding: 0.75rem 0.5rem;
  }
}

@media (min-width: 768px) {
  .showcase1 table.table.w-75 {
    width: 100% !important;
  }
  .showcase1 table tr th:first-child {
    width: 35%;
  }
  .q-tokenomics-th-position {
    font-size: 0.9rem;
    padding: 6px;
  }
  .tokenomics-table-title {
    font-size: 1.88rem;
    width: 95%;
    margin-left: 2.5%;
  }
  .tokenomics-table-td {
    font-size: 0.9rem;
  }
  .table > :not(caption) > * > * {
    padding: 0.75rem 0.5rem;
  }
}

/* ---------  */

.new-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.1rem;
  padding: 0 6rem;
  color: #fff;
  position: sticky;
  z-index: 1020;
}
@media (max-width: 1450px) {
  .new-header {
    padding: 0 4rem;
  }
}
@media (max-width: 1350px) {
  .new-header {
    padding: 0 2rem !important;
  }
}
.navbar-brand {
  justify-content: center;
  align-items: center;
  padding: 0;
}
.new-nav {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.new-nav .new-nav-links {
  color: #fff;
  text-decoration: none;
}

.new-nav .new-nav-links:hover {
  cursor: pointer;
}

.new-header .new-nav-btn {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  display: none;
  font-size: 2rem;
}

.new-nav-responsive-btn {
  display: none;
}

@media only screen and (max-width: 1140px) {

  
  .nav-new-buttons-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-new-buttons {
    display: none;
  }
  
  .new-header .new-nav-btn {
    display: block;
  }

  .new-nav-responsive-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .new-header .new-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    background-color: #0a0909;
    opacity: 0.95;
    transition: 1s;
    transform: translateY(-100vh);
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .new-header .responsive-nav {
    transform: none;
  }

  .new-nav .new-nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .new-nav .new-nav-links {
    font-size: 1.5rem;
  }
}
.partners-text {
  font-weight: normal;
}
.phantom-img {
  opacity: 0.5;
  height: 17rem;
  width: 16rem;
  object-fit: cover;
  border-radius: 14px;
}
.TGE-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 135%;
  /* identical to box height, or 65px */
  text-transform: uppercase;
}
.TGE-button {
  background: #f4f4f8;
  /* box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 40px;
  width: auto;
  height: 56px;
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 135%;
  padding-left: 2rem;
  padding-right: 2rem;
  /* or 24px */

  text-transform: uppercase;

  /* Primary/1 */

  color: #040f24;
}
.twitter-logo {
  padding: 5px;
  cursor: pointer;
  border-radius: 100%;
  border-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  background-color: rgb(255, 255, 255);
  position: relative;
  color: rgb(0, 0, 0);
  width: 1.75rem;
  height: 1.75rem;
}


@media (max-width: 992px) {
  .phantom-img {
    height: auto !important;
  }
}

.buy-container{
  display: flex;
  justify-content: center;
}

@media (max-width: 920px) {
  .buy-container {
    display: flex;
    flex-direction: column;
  }
}

.buy-qubes-container {
  border-radius: 16px;
  background: #fff;
}
.buy-qubes-heading {
  color: #0a0909;

  font-family: Rajdhani;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 625;
  line-height: 150%;
}

.buy-qubes-text {
  color: #0a0909;
  text-align: center;
  font-family: Rajdhani;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 525;
  line-height: 150%;
}

.buy-qubes-text-2 {
  color: #6c6b6b;
  font-family: Rajdhani;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}
.text-back {
  text-decoration: underline;
  cursor: pointer;
}

.small-logo {
  width: 28px;
  height: 28px;
}

.input-text {
  border-radius: 8px;
  border: 1px solid #cecece;
  background: #fff;
}
.inputStyle {
  border-radius: 8px;
  border: 1px solid #cecece;
  background: #fff;

  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  color: #0a0909;
  /* Labels/L2 */
  font-family: Rajdhani;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.payment-button {
  border-radius: 100px;
  background: linear-gradient(180deg, #5444be 0%, #5524eb 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;

  color: #ffffff;
  /* Labels/L2 */
  font-family: Rajdhani;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  margin-bottom: 1rem;
}

.payment-button:hover {
  color: #dbdbdb;
  background: linear-gradient(180deg, #604ed6 0%, #5d2fe9 100%);
}
.hqLogo {
  width: 25% !important;
}


@media (max-width: 920px) {
  .buy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hqLogo {
    text-align: center;
    width: 50% !important;
  }
}

.site-nav {
  background: transparent;
  color: white;
  border: none;
  cursor: pointer !important;
  padding: auto;
  display: flex;
  align-items: center;
  border-radius: 10%;
}

.sitenav-basecard {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem !important;
  flex-direction: column !important;
  gap: 1rem;
}

.sitenav-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}

.sitenav-grid a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10%;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.sitenav-grid a img {
  object-fit: contain;
                                    max-width: 100%;
                                    height: auto;
}

.sitenav-grid a:hover {
  background-color: rgba(255, 255, 255, 0.28);
  transform: translateY(-5px);
}

.comp-logo-display {
  margin: 0;
  width: 100%;
}

.comp-logo-display img {
  width: 220px;
  height: 230px;
  object-fit: contain;
}

.new-mission-reponsive-img {
  display: none !important;
}

@media (max-width: 440px) {
  .new-hero-tag{
    font-size: 24px;
  }

  .pre-intro {
    font-size: 2rem;
  }
}

@media (max-width: 920px) {
  .new-showcase {
    background-color: #000;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/images/Home/qubes_mobile.png');
    background-size: cover;
    object-fit: contain;
  }
}

@media (max-width: 1400px) {

  .newheader .container {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media (max-height: 720px) {
  .new-hero-tag{
    font-size: 20px;
  }

  .pre-intro {
    font-size: 1.6rem;
  }

}

.new-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.new-mission-showcase {
  background-size: 100%;
  background-color: #000000;
  padding: 4rem 5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media (min-width: 1280px) {
  .new-mission-showcase {
    height: calc(100vh - 5.1rem);
  }
}

.new-mission-text {
  font-family: "Rajdhani";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 125%;
  /* identical to box height, or 70px */
  text-align: center;
  letter-spacing: 2px;
  color: #ffffff;
}

.new-mission-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin: auto;
  /* or 33px */
  text-align: center;
  color: #ffffff;
  width: 600px;
}

.new-mission-left-title {
  font-family: "Rajdhani";
  font-weight: 600;
  font-size: 5rem;
  line-height: 125%;
  /* identical to box height, or 70px */
  text-align: left;
  letter-spacing: 2px;
  color: #ffffff;
  margin: 0;
}

.new-mission-left-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 150%;
  color: #ffffff;
  /* or 33px */
  text-align: left;
  color: #ffffff;
  width: 80%;
}

.new-mission-right-title {
  font-family: "Rajdhani";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 125%;
  /* identical to box height, or 70px */
  text-align: right;
  letter-spacing: 2px;
  color: #ffffff;
}

.new-mission-right-paragraph {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: right;
  color: #ffffff;
  width: 500px !important;
  margin: auto;
  /* or 33px */
  color: #ffffff;
}

.new-mission-center-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 500px;
}

.new-mission-center-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1640px) {
  .new-mission-text {
    font-size: 2.7rem;
  }
  .new-mission-left-title {
    font-size: 4rem;
  }
  .new-mission-right-title {
    font-size: 2.7rem;
  }
  
  .new-mission-paragraph {
    font-size: 1.4rem;
    width: 450px !important;
  }
  .new-mission-left-paragraph {
    width: 450px !important;
  }
  .new-mission-right-paragraph {
    font-size: 1.4rem;
    width: 450px !important;
  }
}

@media (max-width: 1450px) {
  .new-mission-center-img {
    height: 380px;
    width: 340px;
  }
}

@media (max-width: 1400px) {
  .new-mission-center-img {
    height: 340px;
    width: 300px;
  }

  .new-mission-text {
    font-size: 2.2rem;
  }
  .new-mission-left-title {
    font-size: 4rem;
  }
  .new-mission-right-title {
    font-size: 2.2rem;
  }

  .new-mission-paragraph {
    font-size: 1.2rem;
    width: 400px !important;
  }
  .new-mission-left-paragraph {
    font-size: 1.7rem;
    width: 400px;
  }
  .new-mission-right-paragraph {
    font-size: 1.2rem;
    width: 400px;
  }
}

@media (max-width: 1320px) {
  .new-mission-center-img {
    height: 320px;
    width: 280px;
  }
}

@media (max-width: 1270px) {
  .new-mission-center-img img {
    display: none;
  }

  .new-mission-showcase {
    background-image: url('../src/images/Home/mission-bg-semi-responsive.png') !important;
    background-size: cover; /* Adjust to 'contain', '100% 100%', etc. as needed */
    background-position: center center; /* Adjust the position if necessary */
    background-repeat: no-repeat;
    color: #28282D;
  }
}

@media (max-width: 1080px) {
  .new-mission-center-img {
    display: none;
  }
  .new-mission-bottom {
    flex-direction: column;
    display: flex;
    gap: 2rem;
    margin-top: 2rem  ;
    align-items: start !important;
    margin-bottom: 5rem;
  }

  .new-mission-text {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 125%;
    letter-spacing: 4px;
    color: #ffffff;
    text-align: center;
    margin: 0;
  }
  .new-mission-left-title {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 125%;
    letter-spacing: 4px;
    color: #ffffff;
    text-align: left;
    margin: 0;
  }
  .new-mission-right-title {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 125%;
    letter-spacing: 4px;
    color: #ffffff;
    text-align: right;
    margin: 0;
  }

  .new-mission-paragraph {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 150%;
    color: #ffffff;
    text-align: center;
  }
  .new-mission-left-paragraph {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 150%;
    color: #ffffff;
  }
  .new-mission-right-paragraph {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 150%;
    color: #ffffff;
    margin: 0;
    width: 100%;
  }

  .new-mission-content-container-left{
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .new-mission-content-container-right {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .new-mission-content-container-left p {
    text-align: left;
    margin: 0;
    width: 50% !important;
  }
  .new-mission-content-container-right p {
    text-align: right;
    margin: 0;
    width: 50% !important;
  }

  .new-mission-content-container-left h5 {
    text-align: left;
    margin: 0;
    width: 100% !important;
  }
  .new-mission-content-container-right h5 {
    text-align: right;
    margin: 0;
    width: 100% !important;
  }

  .new-mission-showcase {
    padding: 4rem 5rem;
    width: 100%;
    overflow: hidden !important;
  }
}

@media (max-width: 800px) {
  
  .new-mission-content-container-left{
    align-items: start;
    margin-top: 2rem;
  }

  .new-mission-content-container-right {
    align-items: start;
    margin-top: 2rem;
  }

  .new-mission-left-title {
    font-weight: 700;
    font-size: 2.5rem;
    text-align: left !important;
  }
  .new-mission-right-title {
    font-weight: 700;
    font-size: 2.5rem;
    text-align: left !important;
  }

  .new-mission-left-paragraph {
    text-align: left !important;
  }

  .new-mission-right-paragraph {
    text-align: left !important;
  }

  .new-mission-showcase {
    background-image: url('../src/images/Home/mission-bg-responsive.png') !important;
    background-size: cover; /* Adjust to 'contain', '100% 100%', etc. as needed */
    background-position: center center; /* Adjust the position if necessary */
    background-repeat: no-repeat;
    color: #28282D;
  }

  .new-mission-content-container-left p {
    margin: 0;
    width: 80% !important;
  }

  .new-mission-content-container-right p {
    margin: 0;
    width: 80% !important;
  }

  .new-mission-paragraph {
    margin: 0;
    width: 100% !important;
  }
  
}

@media (max-width: 675px) {

  .new-mission-content-container-left p {
    margin: 0;
    width: 100% !important;
  }

  .new-mission-content-container-right p {
    margin: 0;
    width: 100% !important;
  }

  .new-mission-showcase {
    padding: 4rem 2rem;
  }
  
}

@media (max-width: 675px) {
  .new-mission-left-paragraph {
    font-weight: 500;
    font-size: 1.3rem;
    margin-top: 0.5rem !important;
  }
  .new-mission-right-paragraph {
    font-weight: 500;
    font-size: 1.3rem;
    margin-top: 0.5rem !important;
  }
}

.container-bg-our-services {
  /* background-image: url("/img/BG\ 1.svg"); */
  background-repeat: no-repeat;
  padding-left: 1em;
  padding-right: 1em;
  
}

.our-services {
  background-color: #000;
  background-image: url('../src/images/CorporateFacelift/img/traps-bg.png');
  background-repeat: no-repeat;
  background-size: cover; /* Ensures the background image covers the entire section */
  background-position: center; /* Centers the background image */
  object-fit: cover;
}

@media (max-width: 720px) {
  .our-services {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
  }
}

.our-services-title-container {
  width: 100%;
}

.our-services-title {
  font-family: "Rajdhani";
  font-weight: 400;
  font-size: 4.5rem;
  line-height: 125%;
  /* identical to box height, or 70px */
  text-align: center;
  letter-spacing: 2px;
  color: #ffffff;
}

.our-services-content-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.our-services-card {
  background-color: #000;
  border: 0.5px solid #fff;
  padding: 1.3rem;
  width: 300px;
  height: 550px;
}

.our-services-card-title {
  font-family: "Rajdhani";
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  /* identical to box height, or 70px */
  text-align: center;
  letter-spacing: 0.8px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
}

.our-services-card-text {
  font-family: "Rajdhani";
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 140%;
  /* identical to box height, or 70px */
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-wrap: wrap;
}

.our-service-card-image {
  width: 100%;
  height: 25%;
  max-height: 30%;
  padding: 1rem;
}

.our-service-card-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1410px) {
  .our-services-card {
    width: 330px;
  }
}

@media (max-width: 1270px) {
  .our-services {
    display: flex;
    padding-top: 3rem !important;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}


@media (max-width: 1250px) {
  .our-services-content-container {
    justify-content: center !important;
    gap: 2.5rem;
    width: 100%;
  }
  
  .container-bg-our-services {
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
  }

  .our-services-card {
    width: 300px;
    height: 560px;
  }

  .our-service-card-image {
    height: 30%;
  }

  
  .our-services-card-title {
    font-size: 1.7rem;
  }
}

@media (max-width: 1110px) {
  .our-services {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .our-services-content-container {
    justify-content: center !important;
    gap: 1.8rem;
    width: 100%;
  }

  .our-services-card {
    width: 260px;
  }

  .our-service-card-image {
    height: 190px;
  }

  .our-services-card-text {
    font-size: 1.070rem;
  }
}

@media (max-width: 640px) {
  .our-services-content-container {
    gap: 2.5rem;
  }
  .our-services-card {
    width: 360px;
    height: 530px;
  }

  .our-service-card-image {
    height: 250px;
  }

  
  .our-services-card-title {
    font-size: 1.7rem;
  }
}

@media (max-width: 490px) { 
  .our-services-card {
    width: 330px;
  }
}

@media (max-width: 420px) { 
  .our-services-title {
    font-size: 1.7rem !important;
  }
  .our-service-card-image {
    height: 210px;
  }
}

@media (max-width: 1080px) { 
  .our-services-title {
    font-size: 2.8rem !important;
  }
}

@media (max-width: 720px) { 
  .our-services-title {
    font-size: 2.2rem !important;
  }
}


@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slidetwo {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.companies {
  background-color: #000;
  padding-top: 5rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-x: hidden !important;
  image-rendering: -webkit-optimize-contrast;
  display: flex;
  align-items: center;
  justify-content: center;

}

@media (min-width: 1280px) {
  .companies {
      min-height: calc(100vh - 5.1rem);
  }
}

.companies-content-container {
  background-repeat: no-repeat;
  overflow: hidden !important;
  image-rendering: -webkit-optimize-contrast;
  max-width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.companies-title-container {
  width: 100%;
}

.companies-title {
  font-family: "Rajdhani";
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 125%;
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
}

.companies-sub-title {
  font-family: "Rajdhani";
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 125%;
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
}

.companies-content-flex {
  width: 100%;
  max-width: 100% !important;
  overflow: hidden !important;
  margin-bottom: 3rem;
}

.companies-flex-container {
  display: grid;
  align-items: center;
  gap: 0.5rem;
  width: 100% !important;
}

.companies-inner-flex,
.companies-inner-flex-two {
  overflow: hidden;
  padding: 22px 0;
  width: 100% !important;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
}

.companies-inner-flex:hover > div,
.companies-inner-flex-two:hover > div {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

.press-slider,
.press-slider-two {
  padding-bottom: 14px;
  padding-top: 14px;
  position: relative;
  align-items: center;
  margin: 0 40px 0 0;
  left: 0 !important;
  flex-direction: row;
  animation-play-state: running;
  display: inline-flex;
}

.press-slider:hover,
.press-slider-two:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.press-content,
.press-content-two {
  height: 120px;
  width: 450px;
  align-items: center;
  margin: 0 50px 0 0;
  padding: 0 10px 0 0;
  cursor: pointer;
  transition: transform 0.4s ease;
}

.press-content img,
.press-content-two img {
  height: 120px;
  width: 450px;
  object-fit: fill;
}

.press-content:hover,
.press-content-two:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.press-slider {
  animation: 130s slide infinite linear;
}

.press-slider-two {
  animation: 50s slidetwo infinite linear;
}


.companies-partners-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.companies-partners-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  width: 75%;
}


@media (max-width: 1280px) { 
  .companies-partners-flex {
    width: 90%;
  }
}

@media (max-width: 1700px) { 
  .companies-partners-container {
    align-items: center;
    width: 100%;
  }

  .companies-partners-flex {
    width: 95%;
  }
}

@media (max-width: 1480px) {

  .companies-partners-flex {
    width: 95%;
  }
}

@media (max-width: 1330px) {

  .companies-partners-flex {
    width: 95%;
  }
}


@media (max-width: 1250px) {

  .companies-partners-flex {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 4rem;
  }
}

.companies-partners {
  height: 135px;
  width: 255px;
  cursor: pointer;
}

@media (max-width: 660px) {
  .companies-partners {
    width: 300px;
    height: 150px;
  }
}

.companies-partners:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.companies-partners img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

@media (max-width: 530px) {
  .companies-title {
    font-size: 2.4rem;
  }
  .companies-sub-title {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .companies-title {
    font-size: 2.2rem;
    margin: 2rem;
  }
}


/* tech agenda */

.container-bg-tech-agenda {
  background-repeat: no-repeat;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 100% !important;
}

.tech-agenda {
  background-color: #000;
  /* background-image: url('../src/images/Home/our-services-bg.png'); */
  object-fit: cover;
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.tech-agenda-title-container {
  width: 100%;
}

.tech-agenda-title {
  font-family: "Rajdhani";
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 125%;
  text-align: center;
  letter-spacing: 2px;
  color: #ffffff;
}

.tech-agenda-content-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center !important;
  gap: 2.5rem;
  width: 100%;
}

.tech-agenda-card {
  background-color: #000;
  border: 2px solid #F6C410;
  padding: 4rem 2rem;
  width: 300px;
  height: 630px;
}

.tech-agenda-card-text {
  font-family: "Rajdhani";
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 135%;
  text-align: center;
  color: #ffffff;
}

.tech-agenda-card-image {
  width: 100%;
  height: 200px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.tech-agenda-card-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1410px) {
  .tech-agenda-card {
    padding: 4rem 1rem;
    width: 360px;
  }

}

@media (max-width: 1270px) {
  .tech-agenda {
    display: flex;
    padding-top: 3rem !important;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .tech-agenda-card-text {
    font-size: 1.4rem;
  }

  .tech-agenda-card {
    height: 590px;
  }
}

@media (max-width: 1250px) {
  .tech-agenda-content-container {
    justify-content: center !important;
    gap: 2.5rem;
    width: 100%;
  }

  .container-bg-tech-agenda {
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
  }

  .tech-agenda-card {
    width: 300px;
    padding: 2rem 1rem;
  }

  .tech-agenda-card-image {
    height: 230px;
  }

  .tech-agenda-card-title {
    font-size: 1.7rem;
  }
}

@media (max-width: 1110px) {
  .tech-agenda {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .tech-agenda-content-container {
    justify-content: center !important;
    gap: 1.8rem;
    width: 100%;
  }
  

  .tech-agenda-card {
    width: 280px;
    height: 550px;
    padding: 2rem 0.5rem;
  }

  .tech-agenda-card-image {
    height: 190px;
  }

  .tech-agenda-card-text {
    font-size: 1.3rem;
  }

}

@media (max-width: 690px) {
  .tech-agenda-card {
    width: 360px;
    height: 550px;
    padding: 2rem 1.2rem;
  }

  .tech-agenda-card-image {
    height: 190px;
  }

  .tech-agenda-card-text {
    font-size: 1.5rem;
  }
}

@media (max-width: 640px) {
  .tech-agenda-content-container {
    gap: 2.5rem;
  }

  
}

@media (max-width: 490px) {
  .tech-agenda-card {
    width: 330px;
  }
}

@media (max-width: 490px) {
  .tech-agenda-card {
    width: 330px;
  }
}

@media (max-width: 440px) {
  .tech-agenda-card-text {
    font-size: 1.4rem;
  }
}

@media (max-width: 410px) {
  .tech-agenda-card {
    height: 530px;
  }
  .tech-agenda-card-text {
    font-size: 1.3rem;
  }
}

@media (max-width: 350px) {
  .tech-agenda-card-text {
    font-size: 1.2rem;
  }
}

.footer {
  z-index: 1001;
  
}
.footer3-left {
  display: flex;
  align-items: center;
}

.footer3-left a {
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  text-decoration: none;
  color: white;
}
.footer3-left p{
  margin-bottom: 0;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  text-decoration: none;
  color: white;
}


/* responsive footer */

.footer3-left-responsive {
  display: none !important;
  flex-direction: column !important;
  align-items: center;
}

.footer3-left-responsive a {
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  text-decoration: none;
  color: white;
}
.footer3-left-responsive p {
  margin-bottom: 0;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  text-decoration: none;
  color: white;
}

.container-footer3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 990px) {
  .container-footer3 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

}

@media (max-width: 750px) {
  .footer3-left {
    display: none !important;
  }

  .footer {
    position: relative !important;
  }

  .footer3-left-responsive {
    display: flex !important;
  }

  .footer3-left-responsive p {
    margin-bottom: 5px;
  }
}

.errorpage-img {
  height: 250px;
  width: 250px;
}

.newgen-section {
  background-color: #000;
  padding-top: 4rem !important;
  min-height: calc(100vh - 5.1rem);
}

@media (min-width: 1280px) {
  .newgen-Section {
      min-height: calc(100vh - 5.1rem);
  }
}
.newgen-quote {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 400;
  font-size: 2.7rem;
  line-height: 140%;
  /* or 67px */

  text-transform: none;
  text-align: center !important;

  color: #ffffff;
  margin-bottom: 1.5rem;
}

@media (max-width: 1280px) {
  .newgen-quote {
    font-size: 2.3rem;
  }
}

@media (max-width: 980px) {
  .newgen-quote {
    font-size: 2rem;
  }
}

@media (max-width: 720px) {
  .newgen-quote {
    font-size: 1.8rem;
  }
}

.newgen-items-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 5rem;
  padding: 1rem;
}

.newgen-col {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 1080px) {
  .newgen-items-container {
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
  }

  .newgen-col {
    width: 80%;
  }
}

.newgen-item-title{
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin: 0;
  text-align: center;
  width: 100%;
  line-height: 100%;
  margin-bottom: 0.5rem;
}

.newgen-item-text{
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin: 0;
  text-align: center;
  width: 100%;
}

@media (max-width: 1080px) {
  .newgen-item-title{
    font-size: 26px;
    text-align: center;
  }

  .newgen-item-text {
    font-size: 18px;
    text-align: center;
  }
}


.newgen-title {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 140%;
  /* or 67px */

  text-transform: none;
  text-align: center !important;

  color: #ffffff;
  margin-bottom: 1.5rem;
}

@media (max-width: 1280px) {
  .newgen-title {
    font-size: 1.4rem;
  }
}

@media (max-width: 980px) {
  .newgen-title {
    font-size: 1.2rem;
  }
}

@media (max-width: 720px) {
  .newgen-quote {
    font-size: 1.7rem;
  }
}